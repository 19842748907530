/*eslint-disable*/
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Footer from 'home-components/Footer/Footer.js'

import styles from 'assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js'

const useStyles = makeStyles(styles)

export default function SectionFooter() {
  const classes = useStyles()
  return (
    <Footer
      theme="dark"
      content={
        <div>
          <div className={classes.left}>
            <a
              href="www.topedsys.com"
              target="_blank"
              className={classes.footerBrand}
            >
              &copy; {1900 + new Date().getYear()} Platnum Tech
            </a>
          </div>
        </div>
      }
    />
  )
}

import {
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const userProfileStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  cardCategory: {
    marginTop: '10px',
    color: grayColor[0] + ' !important',
    textAlign: 'center',
  },
  description: {
    color: grayColor[0],
  },
  updateProfileButton: {
    float: 'right',
  },
  actionButton: {
    margin: '0 0 0 5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px',
    },
  },
  icon: {
    verticalAlign: 'middle',
    width: '17px',
    height: '17px',
    top: '-1px',
    position: 'relative',
  },
  mr10: {
    marginRight: 10,
  },
  bold: {
    fontWeight: 400,
  },
  flexStart: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}
export default userProfileStyles;

import React from 'react';
import { useSelector } from 'react-redux';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';

import FeeTypes from './components/FeeTypes';
import ClassFees from './components/ClassFees';

export default function Fees() {
  const hasFees = useSelector(state => !!state.fees?.fee_types?.length),
    tabs = [
      {
        tabButton: 'Fee types',
        tabContent: <FeeTypes />,
      },
      {
        tabButton: 'Class fees',
        tabContent: <ClassFees />,
        display: hasFees,
      },
    ].filter(({ display }) => (display === undefined ? true : display));
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <NavPills color="rose" alignCenter tabs={tabs} />
        </GridItem>
      </GridContainer>
    </div>
  );
}

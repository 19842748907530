import React, { useEffect } from 'react'
import { connect } from 'react-redux'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridContainer from 'home-components/Grid/GridContainer.js'
import GridItem from 'home-components/Grid/GridItem.js'

import ResultSection from './ResultSection'

import contactsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js'
import city from 'assets/img/office1.jpg'
import FeeSection from './FeeSection'
import { getCurrentTermFees } from '../actions'

const useStyles = makeStyles(contactsStyle)

function Activities({ current_term_fees, getCurrentTermFees }) {
  
  const classes = useStyles()

  useEffect(() => {
    getCurrentTermFees()
  }, [getCurrentTermFees])
  
  return (
    <div className="cd-section">
      <div
        id="contact"
        className={classes.contacts + ' ' + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5}>
              <ResultSection />
            </GridItem>
            {current_term_fees?.length && (
              <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                <FeeSection />
              </GridItem>
            )}
          </GridContainer>
        </div>
      </div>
      {/* Activities us 1 END */}
    </div>
  )
}
const mapStateToProps = state => state.school

const mapDispatchToProps = dispatch => ({
  getCurrentTermFees: () => dispatch(getCurrentTermFees()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Activities)

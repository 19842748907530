import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormDialog from "../../../common/components/FormDialog";
import MenuButton from "../../../common/components/MenuButton";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

import {
  getScores,
  editScore,
  createScore,
  deleteScore,
  createScores,
  getStudent,
  getStudentCurrentClass,
  updateComment,
} from "../actions";
import { getSubjects } from "../../Subjects/actions";
import { getResultInfo } from "../../Result/actions";
import { actions } from "../redux";
import { actions as commonActions } from "../../../common/redux";
import {
  formatDate,
  capitalizeFirst,
  sumValuesInArray,
  grades,
} from "../../../helpers";

const useStyles = makeStyles(styles);

function ManageScores({
  getScores,
  scores,
  createScore,
  editScore,
  deleteScore,
  createScores,
  getStudent,
  getClass,
  student,
  current_student_class,
  getSubjects,
  subjects,
  getResultInfo,
  comments,
  updateComment,
  showError,
}) {
  const { id: student_id, class_id } = useParams(),
    [checked, setChecked] = React.useState([]),
    [scoreValues, setScoreValues] = React.useState(null),
    [updateObject, setUpdateObject] = React.useState(null),
    [open_create_dialog, updateCreateDialog] = React.useState(null),
    [open_csv_dialog, updateCSVDialog] = React.useState(null),
    [open_comments_dialog, toggleCommentsDialog] = React.useState(null),
    existing_subjects = scores?.map((s) => s["subject.id"]),
    fields = ["first_test", "second_test", "continuous_assessment", "exam"],
    remaining_subjects = subjects?.filter(
      ({ value }) => existing_subjects?.indexOf(value) === -1,
    ),
    handleToggle = (value) => {
      const currentIndex = checked.indexOf(value),
        newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    },
    classes = useStyles(),
    fillButtons = (data) => {
      const { id, first_test, second_test, continuous_assessment, exam } = data;
      return [
        {
          color: "info",
          icon: Edit,
          func: () => {
            setScoreValues({ id, name: data["subject.name"] });
            setUpdateObject({
              first_test,
              second_test,
              continuous_assessment,
              exam,
            });
          },
        },
        {
          color: "danger",
          icon: Close,
          func: () => deleteScore(class_id, student_id, id),
        },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
    };
  const handleSubmit = (data, type) => {
    let reset = updateCreateDialog,
      handler = createScore;
    const total = sumValuesInArray(data, fields);
    if (total > 100) return showError("Total must not be more than 100");
    if (type === "edit") {
      reset = setUpdateObject;
      data.id = scoreValues.id;
      handler = editScore;
    }
    handler({ class_id, student_id, ...data, reset });
  };
  React.useEffect(() => {
    if (student_id) {
      getScores(class_id, student_id);
      getStudent(student_id);
      getClass(student_id);
      getResultInfo(class_id, student_id);
      getSubjects();
    }
  }, [
    getScores,
    student_id,
    class_id,
    getStudent,
    getClass,
    getResultInfo,
    getSubjects,
  ]);

  return (
    <GridContainer>
      {open_create_dialog && remaining_subjects?.length && (
        <FormDialog
          size={6}
          title="Create score"
          selectFields={{
            subject: remaining_subjects,
          }}
          numberFields={{
            first_test: "",
            second_test: "",
            continuous_assessment: "",
            exam: "",
          }}
          disabledFields={{
            total: [
              "first_test",
              "second_test",
              "continuous_assessment",
              "exam",
            ],
          }}
          handleClose={() => updateCreateDialog(false)}
          submit={handleSubmit}
        />
      )}
      {updateObject && (
        <FormDialog
          skipValidation
          size={6}
          title={`Update ${scoreValues.name}`}
          numberFields={updateObject}
          disabledFields={{ total: fields }}
          handleClose={() => setUpdateObject(null)}
          submit={(body) => handleSubmit(body, "edit")}
        />
      )}
      {open_csv_dialog && (
        <FormDialog
          title="Create scores from csv file"
          csvFields={["subject_id", ...fields]}
          csvSampleLink="https://sample-fil.s3.eu-west-3.amazonaws.com/sample_scores.csv"
          handleClose={() => updateCSVDialog(false)}
          submit={(scores) =>
            createScores({
              class_id,
              student_id,
              scores,
              reset: () => updateCSVDialog(null),
            })
          }
        />
      )}
      {open_comments_dialog && (
        <FormDialog
          title="Update comment"
          formFields={open_comments_dialog}
          handleClose={() => toggleCommentsDialog(null)}
          submit={(update, reset) =>
            updateComment({ class_id, student_id, reset, ...update })
          }
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={8}>
                <Typography variant="subtitle2">
                  Name:{" "}
                  <u>
                    {`${capitalizeFirst(student?.firstname)} ${
                      student?.middlename
                        ? capitalizeFirst(student?.middlename)
                        : ""
                    } ${capitalizeFirst(student?.lastname)}`}
                  </u>
                </Typography>
                <Typography variant="subtitle2">
                  Session:{" "}
                  <u>
                    {current_student_class?.session_term_class
                      ? `${
                          formatDate(
                            current_student_class?.session_term_class?.session
                              ?.start,
                          )?.split("/")[0]
                        }/${
                          formatDate(
                            current_student_class?.session_term_class?.session
                              ?.end,
                          ).split("/")[0]
                        }`
                      : "No session created"}
                  </u>
                </Typography>
                <Typography variant="subtitle2">
                  Term:{" "}
                  <u>
                    {current_student_class?.session_term_class?.session_term
                      ?.term?.name
                      ? current_student_class?.session_term_class?.session_term
                          ?.term?.name
                      : "No term created yet"}
                  </u>{" "}
                </Typography>
                <Typography variant="subtitle2">
                  Class:{" "}
                  <u>
                    {current_student_class?.session_term_class?.class?.name}
                  </u>{" "}
                </Typography>
                <Typography variant="subtitle2">
                  Teacher's comment: {comments?.teachers_comment}
                  <Button
                    round
                    simple
                    size="lg"
                    color="info"
                    className={classes.actionButton}
                    onClick={() =>
                      toggleCommentsDialog({
                        teachers_comment: comments?.teachers_comment,
                      })
                    }
                  >
                    <Edit />
                  </Button>
                </Typography>
                <Typography variant="subtitle2">
                  Proprietor's comment: {comments?.principals_comment}
                  <Button
                    round
                    simple
                    size="lg"
                    color="info"
                    className={classes.actionButton}
                    onClick={() =>
                      toggleCommentsDialog({
                        principals_comment: comments?.principals_comment,
                      })
                    }
                  >
                    <Edit />
                  </Button>
                </Typography>
              </GridItem>
              <GridItem
                container
                xs={4}
                justify="flex-end"
                alignItems="flex-start"
              >
                <Button color="info">
                  <Link
                    underline="none"
                    color="inherit"
                    href={`/admin/results/${current_student_class?.session_term_class?.id}/${student_id}`}
                  >
                    View Result
                  </Link>
                </Button>
                {!!remaining_subjects?.length && (
                  <MenuButton
                    title="Add"
                    class_name="floatRight"
                    buttons={[
                      {
                        name: "Add One",
                        handler: () => {
                          updateCreateDialog(true);
                        },
                      },
                      {
                        name: "Upload CSV",
                        handler: () => updateCSVDialog(true),
                      },
                    ]}
                  />
                )}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardBody>
            {scores?.length ? (
              <Table
                tableHead={[
                  "",
                  "Subject",
                  "Test 1",
                  "Test 2",
                  "CA",
                  "Exam",
                  "Grade",
                  "Total",
                  "Position",
                  "",
                ]}
                tableData={scores?.map((score) => [
                  <Checkbox
                    key="key"
                    className={classes.positionCheckbox}
                    tabIndex={-1}
                    onClick={() => handleToggle(score.id)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />,
                  score["subject.name"],
                  score.first_test,
                  score.second_test,
                  score.continuous_assessment,
                  score.exam,
                  grades(score.total),
                  score.total,
                  score.position,
                  fillButtons(score),
                ])}
                customCellClasses={[
                  classes.checkCell,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                customHeadCellClasses={[
                  classes.checkCell,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
              />
            ) : (
              <p>
                No scores entered yet. Click on the add button to enter
                student's scores
              </p>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapDispatchToProps = dispatch => ({
  getSubjects: () => dispatch(getSubjects()),
  getStudent: id => dispatch(getStudent(id)),
  getClass: id => dispatch(getStudentCurrentClass(id)),
  getScores: (class_id, student_id) =>
    dispatch(getScores(class_id, student_id)),
  getResultInfo: (class_id, student) =>
    dispatch(getResultInfo(class_id, student)),
  createScore: body => dispatch(createScore(body)),
  createScores: body => dispatch(createScores(body)),
  editScore: body => dispatch(editScore(body)),
  updateComment: body => dispatch(updateComment(body)),
  deleteScore: (class_id, student_id, id) =>
    dispatch(
      commonActions.showConfirmation({
        title: "Delete score",
        message: "delete score",
        open: true,
        handler: () => deleteScore(class_id, student_id, id)(dispatch),
      })
    ),
  toggleDialog: (key, open) =>
    dispatch(actions.updateField({ key, value: open })),
  showError: (message) =>
    dispatch(commonActions.toggleErrorAlert(message)),
});

const mapStateToProps = state => ({
  ...state.students,
  comments: state.results.result_info.comments,
  subjects: state.subjects.subjects?.map(({id, name}) => ({value: id, label: name })),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageScores);

import React from 'react'
import { connect } from 'react-redux'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
import PinDrop from '@material-ui/icons/PinDrop'
import Phone from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
// core components
import GridContainer from 'home-components/Grid/GridContainer.js'
import GridItem from 'home-components/Grid/GridItem.js'
import InfoArea from 'home-components/InfoArea/InfoArea.js'
import Card from 'home-components/Card/Card.js'

import contactsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js'

import city from 'assets/img/office1.jpg'

const useStyles = makeStyles(contactsStyle)

function Contact({ school }) {
  const classes = useStyles()

  return (
    <div className="cd-section">
      <div
        id="contact"
        className={classes.contacts + ' ' + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5}>
              <h2 className={classes.title}>Get in Touch</h2>
              <h5 className={classes.description}>
                You need more information? Kindly send us a message.
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="Find us at the office"
                description={<span>{school?.address}</span>}
                icon={PinDrop}
              />
              <InfoArea
                className={classes.infoArea}
                title="Email us"
                description={
                  <span>
                    {school?.email || (school && school['users.email'])}
                    <br />
                  </span>
                }
                icon={EmailIcon}
              />
              <InfoArea
                className={classes.infoArea}
                title="Give us a ring"
                description={
                  <span>
                    {school && school['users.phone']}
                    <br />
                  </span>
                }
                icon={Phone}
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}></Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Contact us 1 END */}
    </div>
  )
}
const mapStateToProps = state => state.school

export default connect(mapStateToProps, null)(Contact)

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { TailSpin } from 'react-loading-icons';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Email from '@material-ui/icons/Email';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';

import { login } from '../actions';
import { checkInvalid } from 'helpers';

const useStyles = makeStyles(styles);

function LoginPage({ login }) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden'),
    classes = useStyles(),
    state = {
      email: '',
      password: '',
    },
    [form, setForm] = React.useState(state),
    [loading, setLoading] = React.useState(false),
    { email, password } = form,
    handleChange = ({ name, value }) => {
      setForm({
        ...form,
        [name]: value,
      });
    };
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    login(form, () => setLoading(false));
  };
  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('');
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: email,
                    name: 'email',
                    onChange: e => handleChange(e.target),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: password,
                    name: 'password',
                    onChange: e => handleChange(e.target),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Button
                      disabled={loading || checkInvalid(form)}
                      round
                      block
                      color="primary"
                      // onClick={handleSubmit}
                      type="submit"
                    >
                      Login
                      {loading && (
                        <TailSpin
                          stroke="#fff"
                          className={{ ...classes.icons, marginLeft: '1em' }}
                        />
                      )}
                    </Button>
                  </GridItem>
                  <GridItem xs={12}>
                    <Link to="/auth/register">
                      <Button color="rose" simple size="lg" block>
                        Don't have an account? Register
                      </Button>
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  login: (data, reset) => dispatch(login(data, reset)),
});

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

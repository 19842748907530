import axios from 'axios';
import { actions } from './redux';
import { BACKEND_API } from 'consts';
import { checkRequiredFields, mapReponseOptions } from '../../helpers';
import {
  CSVFormatError,
  successfulCreateRequest,
  successfulUpdateRequest,
  successfulDeleteRequest,
} from '../../messages';
import { actions as commonActions } from '../../common/redux';
import { getAllStudents } from '../../views/Students/actions';
const {
  showNotification,
  toggleErrorAlert,
  setLoading,
  cancelConfirmation,
} = commonActions;

export const getClasses = () => dispatch =>
  axios
    .get(`${BACKEND_API}/classes`)
    .then(({ data }) => {
      dispatch(
        actions.updateField({
          key: 'school_classes',
          value: mapReponseOptions(data.data),
        })
      );
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });

export const createClass = body => dispatch => {
  dispatch(setLoading(true));
  return axios
    .post(`${BACKEND_API}/classes`, body)
    .then(() => {
      dispatch(getClasses());
      dispatch(showNotification({ message: successfulCreateRequest('Class') }));
      dispatch(
        actions.updateField({ key: 'open_create_dialog', value: false })
      );
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const editClass = ({ id, name }) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/classes/${id}`, { name })
    .then(() => {
      dispatch(getClasses());
      dispatch(showNotification({ message: successfulUpdateRequest('Class') }));
      dispatch(actions.updateField({ key: 'open_edit_dialog', value: false }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const deleteClass = id => dispatch => {
  dispatch(setLoading(true));
  return axios
    .delete(`${BACKEND_API}/classes/${id}`)
    .then(() => {
      dispatch(getClasses());
      dispatch(showNotification({ message: successfulDeleteRequest('Class') }));
      dispatch(cancelConfirmation());
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const createClasses = classes => dispatch => {
  dispatch(setLoading(true));
  const isInvalid = classes.some(item => checkRequiredFields(item, ['name']));
  if (isInvalid) return dispatch(toggleErrorAlert(CSVFormatError()));
  return axios
    .post(`${BACKEND_API}/classes/bulk`, { classes })
    .then(() => {
      dispatch(getClasses());
      dispatch(
        showNotification({ message: successfulCreateRequest('Classes') })
      );
      dispatch(actions.updateField({ key: 'open_csv_dialog', value: false }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getTermClasses = () => dispatch =>
  axios
    .get(`${BACKEND_API}/terms/classes`)
    .then(({ data }) => {
      dispatch(
        actions.updateField({
          key: 'term_classes',
          value: data.data.map(s => ({
            ...s,
            value: s.id,
            label: s['class.name'],
          })),
        })
      );
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });

export const createTermClass = body => dispatch => {
  dispatch(setLoading(true));
  return axios
    .post(`${BACKEND_API}/terms/classes`, body)
    .then(() => {
      dispatch(getClasses());
      dispatch(showNotification({ message: successfulCreateRequest('Class') }));
      dispatch(
        actions.updateField({ key: 'open_create_dialog', value: false })
      );
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const deleteTermClass = id => dispatch => {
  dispatch(setLoading(true));
  return axios
    .delete(`${BACKEND_API}/terms/classes/${id}`)
    .then(() => {
      dispatch(getTermClasses());
      dispatch(showNotification({ message: successfulDeleteRequest('Class') }));
      dispatch(cancelConfirmation());
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getTermClassStudents = id => dispatch => {
  dispatch(setLoading(true));
  dispatch(
    actions.updateField({
      key: 'term_class_id',
      value: id,
    })
  );
  return axios
    .get(`${BACKEND_API}/students/class/${id}`)
    .then(({ data }) => {
      dispatch(setLoading(false));
      dispatch(
        actions.updateField({
          key: 'term_class_students',
          value: data.data,
        })
      );
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getTermClass = id => dispatch =>
  axios
    .get(`${BACKEND_API}/terms/classes/${id}`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'term_class', value: data.data }));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });

export const createTermClassStudent = (body, cb) => (dispatch, getState) => {
  dispatch(setLoading(true));
  let id = getState().classes.term_class?.id;
  if (body.class) {
    id = body.class;
    delete body.class;
  }
  return axios
    .post(`${BACKEND_API}/students/${id}`, body)
    .then(() => {
      if (cb) cb();
      else {
        dispatch(getTermClassStudents(id));
        dispatch(
          showNotification({ message: successfulCreateRequest('Student') })
        );
        dispatch(
          actions.updateField({ key: 'open_create_dialog', value: false })
        );
        dispatch(setLoading(false));
      }
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const createTermClassStudents = ({ body, reset }) => (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  let classIdentifier = getState().classes.term_class?.id;
  const requiredFields = [
    'firstname',
    'lastname',
    'middlename',
    'dob',
    'gender',
  ];
  if (!classIdentifier) {
    requiredFields.push('class');
    classIdentifier = 'all';
  }
  const isInvalid = body.some(item =>
    checkRequiredFields(item, requiredFields)
  );
  if (isInvalid) return dispatch(toggleErrorAlert(CSVFormatError()));
  return axios
    .post(`${BACKEND_API}/students/bulk/${classIdentifier}`, {
      students: body,
    })
    .then(() => {
      const getAll =
        classIdentifier === 'all' ? getAllStudents : getTermClassStudents;
      dispatch(getAll(classIdentifier));
      dispatch(
        showNotification({ message: successfulCreateRequest('Students') })
      );
      dispatch(actions.updateField({ key: 'open_csv_dialog', value: false }));
      dispatch(setLoading(false));
      reset && reset();
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const deleteTermClassStudent = (class_id, id) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .delete(`${BACKEND_API}/students/${class_id}/${id}`)
    .then(() => {
      dispatch(getTermClassStudents(class_id));
      dispatch(
        showNotification({ message: successfulDeleteRequest('Student') })
      );
      dispatch(cancelConfirmation());
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getCurrentTermClasses = () => dispatch =>
  axios
    .get(`${BACKEND_API}/classes/current`)
    .then(({ data }) => {
      dispatch(
        actions.updateField({
          key: 'current_term_classes',
          value: mapReponseOptions(data.data, 'class.name'),
        })
      );
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });

export const updateTermClassTeacher = ({
  id,
  teacher_id,
  reset,
}) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/terms/classes/${id}`, { teacher_id })
    .then(() => {
      dispatch(setLoading(false));
      dispatch(showNotification({ message: 'Successful' }));
      dispatch(getTermClass(id));
      reset();
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  black_button: {
    borderRadius: 10,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  white_button: {
    borderRadius: 10,
    color: '#000000',
    backgroundColor: '#F1F3F4',
    '&:hover': {
      textDecoration: 'underline',
      color: '#000000',
      backgroundColor: '#F1F3F4',
    },
  },
  red_button: {
    borderRadius: 10,
    backgroundColor: theme.palette.error,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error,
      textDecoration: 'underline',
    },
  },
  green_button: {
    borderRadius: 10,
    backgroundColor: '#2ca40d',
    color: 'white',
    '&:hover': {
      backgroundColor: '#2ca40d',
      textDecoration: 'underline',
    },
  },
  yellow_button: {
    borderRadius: 10,
    backgroundColor: '#eae2b7',
    color: '#836703',
    '&:hover': {
      backgroundColor: '#eae2b7',
      color: '#836703',
      textDecoration: 'underline',
    },
  },
  small_blue_button: {
    borderRadius: 10,
    color: '#2351F6',
    backgroundColor: '#D1D6FF',
    textTransform: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#D1D6FF',
      textDecoration: 'underline',
    },
  },
  small_red_button: {
    borderRadius: 10,
    color: '#F62323',
    backgroundColor: '#FFD1D1',
    textTransform: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFD1D1',
      textDecoration: 'underline',
    },
  },
}))

export const SimpleButton = ({ label, onClick, disabled, style, Icon }) => {
  const classes = useStyles()

  return (
    <Button
      endIcon={Icon && <Icon />}
      size="small"
      color="primary"
      disableElevation
      style={style}
      onClick={onClick}
      classes={{
        root: classes.black_button,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}

export const BlackButton = ({
  label,
  onClick,
  disabled,
  style,
  Icon,
  size = 'small',
}) => {
  const classes = useStyles()

  return (
    <Button
      endIcon={Icon && <Icon />}
      size={size}
      variant="contained"
      color="primary"
      disableElevation
      style={style}
      onClick={onClick}
      classes={{
        root: classes.black_button,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}

export const WhiteButton = ({ label, onClick, disabled, style, Icon }) => {
  const classes = useStyles()

  return (
    <Button
      endIcon={Icon && <Icon />}
      size="small"
      variant="contained"
      color="primary"
      disableElevation
      style={style}
      onClick={onClick}
      classes={{
        root: classes.white_button,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}
export const RedButton = ({ label, onClick, disabled, style, Icon }) => {
  const classes = useStyles()

  return (
    <Button
      endIcon={Icon && <Icon />}
      size="small"
      variant="contained"
      disableElevation
      style={style}
      onClick={onClick}
      classes={{
        root: classes.red_button,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}

export const GreenButton = ({
  label,
  onClick,
  disabled,
  style,
  Icon,
  large,
}) => {
  const classes = useStyles()

  return (
    <Button
      endIcon={Icon && <Icon />}
      size={large ? 'medium' : 'small'}
      variant="contained"
      disableElevation
      style={style}
      onClick={onClick}
      classes={{
        root: classes.green_button,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}

export const YellowButton = ({
  label,
  onClick,
  disabled,
  style,
  Icon,
  large,
}) => {
  const classes = useStyles()

  return (
    <Button
      endIcon={Icon && <Icon />}
      size={large ? 'medium' : 'small'}
      variant="contained"
      disableElevation
      style={style}
      onClick={onClick}
      classes={{
        root: classes.yellow_button,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}

export const SmallBlueButton = ({
  label,
  onClick,
  disabled,
  style,
  Icon,
  large,
}) => {
  const classes = useStyles()

  return (
    <Button
      endIcon={Icon && <Icon />}
      size={large ? 'medium' : 'small'}
      variant="contained"
      color="primary"
      disableElevation
      style={style}
      onClick={onClick}
      classes={{
        root: classes.small_blue_button,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}

export const SmallRedButton = ({
  label,
  onClick,
  disabled,
  style,
  Icon,
  large,
}) => {
  const classes = useStyles()

  return (
    <Button
      endIcon={Icon && <Icon />}
      size={large ? 'medium' : 'small'}
      variant="contained"
      color="primary"
      disableElevation
      style={style}
      onClick={onClick}
      classes={{
        root: classes.small_red_button,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}

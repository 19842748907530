import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  result: null,
  result_info: {
    student: {
      firstname: '',
      lastname: '',
      middlename: '',
      student_id: '',
      dob: '',
    },
    class_detail: {
      'class.name': '',
      'term.name': '',
      'session.start': '',
      'session.end': '',
    },
    total_students: 0,
  },
  stats: {
    average: 0,
    passed: 0,
    obtained: 0,
  },
  scores: null,
  results: null,
  loading: false,
  open_create_dialog: false,
  open_edit_dialog: false,
  open_csv_dialog: false,
}
export const resultSlice = createSlice({
    name: 'result',
    initialState,
    reducers: {
      updateField: (state, { payload }) => {
        state[payload.key] = payload.value
      },
    },
}),
  
  actions = resultSlice.actions

export default resultSlice.reducer

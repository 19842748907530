import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import Button from '../../components/CustomButtons/Button';

const useStyles = makeStyles(() => ({
  overlay: {
    cursor: 'pointer',
    color: 'black',
    fontSize: '1em',
  },
  none: {
    display: 'none',
  },
}));

export default function LocalFileUpload({
  name,
  multiple,
  onChange,
  Component,
  buttonProps,
  accept = 'image/*',
}) {
  const classes = useStyles();
  return (
    <>
      <input
        id={name}
        type="file"
        name={name}
        accept={accept}
        multiple={multiple}
        onChange={({ target }) => onChange(target.files)}
        className={classes.none}
      />
      <label htmlFor={name} className={classes.overlay}>
        {Component ? (
          <Component />
        ) : buttonProps ? (
          <Button component="label" {...buttonProps} htmlFor={name} />
        ) : (
          <EditOutlinedIcon />
        )}
      </label>
    </>
  );
}

import React, { useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// material-ui icons

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

import Logo from "assets/img/logoo.png";
import Person from "assets/img/person.png";

import { capitalizeFirst, formatDate, getAge } from "helpers";
import { IMAGE_BASE } from "consts";
import { getStudentResult } from "../actions";
import { getQueryObject } from "helpers";
import { actions } from "../redux";

const useStyles = makeStyles(styles);

const OverviewItem = ({ name, value }) => {
  const classes = useStyles();
  return (
    <GridItem xs={4} className={classes.overviewItem}>
      <b>{name}</b>: {value}
    </GridItem>
  );
};

const RemarkItem = ({ name, value }) => {
  const classes = useStyles();
  return (
    <GridItem xs={12} className={classes.overviewItem}>
      <b>{name}</b>: {value}
    </GridItem>
  );
};

export default function StudentResult({ hide_print }) {
  const {
    scores,
    position,
    school,
    term_class,
    class_detail,
    total_students,
    student,
    comments,
  } = useSelector((state) => state.school.result);
  const is_pin_valid = useSelector((state) => state.school.is_pin_valid);
  const loading = useSelector((state) => state.common.loading);
  const { pin, student: student_id } = getQueryObject(useLocation(), [
    "pin",
    "student",
  ]);
  const validParams = pin && student_id;
  const navigate = useNavigate();
  let ref = useRef(null),
    classes = useStyles(),
    pass_threshold = 39, // MOVE VALUE TO BACKEND
    no_passed = scores?.filter(({ total }) => total > pass_threshold),
    total = scores?.reduce((acc, curr) => acc + curr.total, 0) || 0;

  const dispatch = useDispatch();
  const updateCheckResult = useCallback(
    () => dispatch(actions.updateCheckResult({ pin, student_id })),
    [],
  );
  const getResult = useCallback(() => dispatch(getStudentResult()), []);

  useEffect(() => {
    if (!validParams) return;
    updateCheckResult();
  }, [updateCheckResult, validParams]);

  useEffect(() => {
    if (!validParams) return;
    getResult();
  }, [getResult, validParams]);

  useEffect(() => {
    if (loading || !school || is_pin_valid) return;
    navigate(`/s/${school.subdomain}/home`);
  }, [loading, school, is_pin_valid]);

  if (!student) return null;
  class Result extends React.PureComponent {
    render() {
      return (
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer
                style={{
                  textAlign: "center",
                  marginBottom: 20,
                }}
              >
                <GridItem xs={3}>
                  <img
                    src={school?.logo ? `${IMAGE_BASE}/${school?.logo}` : Logo}
                    alt="school logo"
                    style={{
                      height: 168,
                    }}
                  />
                </GridItem>
                <GridItem xs={9}>
                  <Typography variant="h2" className={classes.bold}>
                    {school?.name?.toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.bold}>
                    {school?.address}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.bold}>
                    GOVERNMENT APPROVED
                  </Typography>
                  <Typography variant="h6" className={classes.title}>
                    INDIVIDUAL STUDENT ASSESSMENT SHEET
                  </Typography>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={10}>
              <GridContainer>
                <OverviewItem
                  name="NAME"
                  value={`${student?.firstname} ${
                    student?.middlename ? student?.middlename : ""
                  } ${student?.lastname}`}
                />
                <OverviewItem name="CODE" value={student?.student_id} />
                <OverviewItem name="AGE" value={getAge(student?.dob)} />
              </GridContainer>
              <GridContainer>
                <OverviewItem
                  name="CLASS"
                  value={class_detail && class_detail["class.name"]}
                />
                <OverviewItem
                  name="SESSION"
                  value={`${
                    formatDate(
                      class_detail && class_detail["session.start"],
                    )?.split("/")[0]
                  }/${
                    formatDate(
                      class_detail && class_detail["session.end"],
                    ).split("/")[0]
                  }`}
                />
                <OverviewItem
                  name="TERM"
                  value={class_detail && class_detail["term.name"]}
                />
              </GridContainer>
              <GridContainer>
                <OverviewItem name="POSITION" value={position} />
                <OverviewItem name="NO IN CLASS" value={total_students} />
                <OverviewItem
                  name="NO OF SUBJECTS PASSED"
                  value={no_passed?.length}
                />
              </GridContainer>
              <GridContainer>
                <OverviewItem
                  name="AVERAGE PERCENTAGE"
                  value={`${scores ? Math.trunc(total / scores.length) : 0}%`}
                />
                <OverviewItem name="TOTAL OBTAINED" value={total} />
                <OverviewItem
                  name="TOTAL OBTAINABLE"
                  value={scores?.length * 100}
                />
              </GridContainer>
            </GridItem>
            <GridItem xs={2} style={{}}>
              <img
                src={
                  student?.profilepicture
                    ? `${IMAGE_BASE}/${student?.profilepicture}`
                    : Person
                }
                alt="Student's profile"
                style={{
                  height: 70,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              {!!scores?.length && (
                <Table
                  tableHead={[
                    "",
                    "Test 1",
                    "Test 2",
                    "CA",
                    "Exam",
                    "Total",
                    "Grade",
                    "Position",
                  ]}
                  tableData={scores?.map((score) => [
                    score.subject?.name,
                    score.first_test,
                    score.second_test,
                    score.continuous_assessment,
                    score.exam,
                    score.total,
                    score.grade,
                    score.position,
                  ])}
                  customHeadCellClasses={[
                    classes.resultCellHeader,
                    classes.resultCellHeader,
                    classes.resultCellHeader,
                    classes.resultCellHeader,
                    classes.resultCellHeader,
                    classes.resultCellHeader,
                    classes.resultCellHeader,
                    classes.resultCellHeader,
                  ]}
                  customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                  customCellClasses={[
                    classes.resultSubjectCell,
                    classes.resultCell,
                    classes.resultCell,
                    classes.resultCell,
                    classes.resultCell,
                    classes.resultCell,
                    classes.resultCell,
                    classes.resultCell,
                  ]}
                  customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                />
              )}
            </GridItem>
            <GridItem xs={12}>
              <GridContainer>
                <RemarkItem
                  name="Class Teacher's Remark"
                  value={comments?.teachers_comment}
                />
                <RemarkItem
                  name="Class Teacher's Name & Signature"
                  value={
                    term_class ? (
                      <u>{`${capitalizeFirst(
                        term_class["account.title"],
                      )} ${capitalizeFirst(
                        term_class["account.firstname"],
                      )} ${capitalizeFirst(
                        term_class["account.lastname"],
                      )}`}</u>
                    ) : (
                      ""
                    )
                  }
                />
                <RemarkItem
                  name="Principal's Remark"
                  value={comments?.principals_comment}
                />
                <RemarkItem
                  name="Principal's Name & Signature"
                  value={`${capitalizeFirst(
                    school?.owner?.title,
                  )} ${capitalizeFirst(
                    school?.owner?.firstname,
                  )} ${capitalizeFirst(school?.owner?.lastname)}`}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      );
    }
  }
  return (
    <GridContainer
      style={{
        marginTop: 50,
      }}
    >
      {!hide_print && (
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.cardContentRight}>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          color="primary"
                          className={classes.marginRight}
                          // onClick={() => console.log('PRINTING')}
                        >
                          PRINT
                        </Button>
                      )}
                      content={() => ref.current}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      )}
      <Card style={{ color: "#1a5da2" }}>
        <Result ref={ref} />
      </Card>
    </GridContainer>
  );
}

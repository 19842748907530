import React, { useEffect, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import { actions } from '../redux';
import { BACKEND_API } from 'consts';
import { capitalizeFirst } from 'helpers';
import { createAllStudentsScores } from '../actions';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
}));

const AllStudentsScoresDialog = () => {
  const { CSVReader } = useCSVReader();
  const classes = useStyles(),
    dispatch = useDispatch(),
    [studentsScores, setStudentsScores] = useState([]),
    { scoresDialog, scores, validated, errors } = useSelector(
      state => state.students.allStudents
    ),
    update = data => dispatch(actions.updateAllStudentsDialog(data)),
    sampleDatabase = [
      {
        name: 'ade kunle',
        english: '0,0,5,33',
      },
      {
        name: 'dara simi',
        english: '12,8,5,33',
      },
    ],
    hasData = studentsScores?.length;

  useEffect(() => {
    const getResultsData = async () => {
      const { data } = await axios.get(`${BACKEND_API}/scores/students/all`);
      setStudentsScores(data.data);
    };
    getResultsData();
  }, []);

  return (
    <Dialog
      open={scoresDialog}
      onClose={() => update()}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={scores?.length ? 'lg' : 'xs'}
    >
      <DialogTitle id="form-dialog-title" classes={{ root: classes.text }}>
        Upload scores
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          {scores?.length ? (
            <>
              {scores.map((e, i) => {
                const { name, ...subjects } = e;
                return (
                  <GridContainer key={name + i}>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={2}>
                          <TextField
                            margin="dense"
                            name="name"
                            label={capitalizeFirst('student')}
                            type="text"
                            value={name}
                            onChange={({ target }) => {
                              update({
                                scores: [...scores].map((s, j) => {
                                  const ns = { ...s };
                                  if (i === j) ns.name = target.value;
                                  return ns;
                                }),
                              });
                            }}
                            fullWidth
                            InputProps={{
                              className: classes.text,
                            }}
                          />
                        </GridItem>
                        {Object.keys(subjects).map(subject => (
                          <GridItem xs={1} key={subject}>
                            <TextField
                              margin="dense"
                              name="subject"
                              label={capitalizeFirst(subject)}
                              type="text"
                              value={subjects[subject]}
                              onChange={({ target }) => {
                                update({
                                  scores: [...scores].map((s, j) => {
                                    const ns = { ...s };
                                    if (i === j) ns[subject] = target.value;
                                    return ns;
                                  }),
                                });
                              }}
                              fullWidth
                              InputProps={{
                                className: classes.text,
                              }}
                            />
                          </GridItem>
                        ))}
                      </GridContainer>
                      {errors && errors[i] && (
                        <Typography
                          variant="caption"
                          display="block"
                          color="error"
                          gutterBottom
                        >
                          {errors[i]}
                        </Typography>
                      )}
                    </GridItem>
                  </GridContainer>
                );
              })}
            </>
          ) : (
            <>
              <DialogContentText>
                <>
                  Upload your csv file.{' '}
                  <CSVLink
                    target="_blank"
                    data={hasData ? studentsScores : sampleDatabase}
                    filename={`students-scores${hasData ? '' : '-sample'}.csv`}
                  >
                    <u>
                      <i>Download {hasData ? 'current' : 'sample'} database</i>
                    </u>
                  </CSVLink>
                </>
              </DialogContentText>
              <GridContainer>
                <GridItem xs={12}>
                  <CSVReader
                    onDrop={response =>
                      update({
                        scores: response?.slice(1).map(({ data }) => {
                          const obj = {};
                          response[0].data.forEach((r, i) => {
                            obj[r?.toLowerCase()] = data[i];
                          });
                          return obj;
                        }),
                      })
                    }
                    onError={console.error}
                    onRemoveFile={console.log}
                  >
                    <span style={{ color: '#2F313F' }}>
                      Drop CSV file here or click to upload.
                    </span>
                  </CSVReader>
                </GridItem>
              </GridContainer>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => update()} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          disableElevation
          variant="contained"
          disabled={!scores?.length}
          onClick={() => dispatch(createAllStudentsScores())}
        >
          {validated ? 'Submit' : 'Validate'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllStudentsScoresDialog;

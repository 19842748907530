/*eslint-disable*/
import React from 'react'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import GridContainer from 'home-components/Grid/GridContainer.js'
import GridItem from 'home-components/Grid/GridItem.js'
import Parallax from 'home-components/Parallax/Parallax.js'

import About from './Sections/About.js'

import componentsStyle from 'assets/jss/material-kit-pro-react/views/componentsStyle.js'
import Contact from './Sections/Contact.js'
import Products from './Sections/Products.js'

import BG from 'assets/img/bg5.jpg'

const useStyles = makeStyles(componentsStyle)

export default function Components() {
  const classes = useStyles()
  return (
    <div>
      <Parallax image={BG} className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1>SAGE</h1>
                <h3 className={classes.title}>
                  The all in one EDU management solution
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <About />
        <Products />
      </div>
      <Contact />
    </div>
  )
}

import { Route, Routes } from "react-router-dom";
import routes from "routes";

export const getRoutes = (routes, parentPath) =>
  routes.map(({ collapse, views, layout, home, View, ...p }, key) => {
    if (collapse) return getRoutes(views);
    if (layout !== parentPath || home) return null;
    const fullPath = (layout + p.path)?.replace(`${parentPath}/`, "");
    return <Route key={key} element={<View />} path={fullPath} />;
  });

export const PageRoutes = ({ BaseComponent, path }) => (
  <Routes>
    {getRoutes(routes, path)}
    <Route path="*" element={<BaseComponent />} />
  </Routes>
);

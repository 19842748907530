import React, { createRef, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import HomeNavbar from "components/Navbars/HomeNavbar";
import Footer from "views/HomePage/Sections/SectionFooter";
import HomePage from "../views/HomePage/HomePage";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle";
import { seo } from "helpers";
import { PageRoutes } from "./helper";

const useStyles = makeStyles(styles);

export default function HomeLayout() {
  // ref for the wrapper div
  const wrapper = createRef();
  // styles
  const classes = useStyles();
  useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  useEffect(() => {
    seo();
  });
  return (
    <div>
      <HomeNavbar />
      <div className={classes.wrapper} ref={wrapper}>
        <PageRoutes BaseComponent={HomePage} path="/home" />
        <Footer dark />
      </div>
    </div>
  );
}

import React from 'react'

import Header from '../../home-components/Header/Header.js'
import { connect } from 'react-redux'
import { IMAGE_BASE } from 'consts.js'

function SchoolNavbar({ school }) {
  return (
    <Header
      brand="SAGE"
      logo={school?.logo && `${IMAGE_BASE}/${school.logo}`}
      fixed
      color="transparent"
      baseURL={school?.subdomain && `/s/${school?.subdomain}`}
      changeColorOnScroll={{
        height: 400,
        color: "white",
      }}
    />
  );
}

const mapStateToProps = (state) => state.school || {};

export default connect(mapStateToProps)(SchoolNavbar);

import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { actions } from "../redux";

import styles from "assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.js";

const useStyles = makeStyles(styles);

const Notification = ({ message, open, close, type }) => {
  const [openSnack, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);

  React.useEffect(() => {
    setOpen(open);
    setMessageInfo({ message, key: new Date().getTime() });
  }, [message, open, setOpen, setMessageInfo]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    close();
    setOpen(false);
  };

  const handleExited = () => {
    console.log("handleExited CALLED");
    setMessageInfo(undefined);
    close();
  };

  const classes = useStyles();
  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
        message={messageInfo ? messageInfo.message : undefined}
        classes={{
          anchorOriginTopCenter: classes.top20,
          anchorOriginTopRight: classes.top40,
          anchorOriginTopLeft: classes.top40,
        }}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              type="reset"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
        ContentProps={{
          classes: {
            root: classes.root + " " + classes[type],
            message: classes.message,
          },
        }}
      />
    </div>
  );
};
const mapStateToProps = state => ({
  ...state.common.notification,
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(actions.closeNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

import { Page as PdfPage } from '@react-pdf/renderer';

const Page = ({ className, pdfMode, children }) => {
  return (
    <>
      {pdfMode ? (
        <PdfPage size="A4" style={className ? className : ''}>
          {children}
        </PdfPage>
      ) : (
        <div className={className ? className : ''}>{children}</div>
      )}
    </>
  );
};

export default Page;

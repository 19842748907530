import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

import Button from 'components/CustomButtons/Button';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';

const useStyles = makeStyles(styles);

export default function FadeMenu({
  title,
  buttons,
  class_name,
  color = 'success',
}) {
  const [anchorEl, setAnchorEl] = React.useState(null),
    classes = useStyles(),
    open = Boolean(anchorEl),
    handleClick = event => setAnchorEl(event.currentTarget),
    handleClose = () => {
      setAnchorEl(null);
    };

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes[class_name]}
        color={color}
      >
        {title}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {buttons.map(({ name, Component, handler }, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handler && handler();
              handleClose();
            }}
          >
            {Component ? <Component /> : name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

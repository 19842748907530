import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  form: {
    name: '',
  },
  teacher: null,
  teachers: null,
  loading: false,
}
export const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
      updateField: (state, { payload }) => {
        state[payload.key] = payload.value
      },
      updateForm: (state, action) => {
        state.form = {
          ...state.form,
          [action.field]: action.value,
        }
      },
    },
  }),
  actions = teacherSlice.actions

export default teacherSlice.reducer

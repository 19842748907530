import React from "react";
import cx from "classnames";
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'

// core components
import Button from 'components/CustomButtons/Button'

import styles from 'assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js'

import Logo from '../../assets/img/sage.png'

const useStyles = makeStyles(styles)

export default function AuthNavbar(props) {
  const classes = useStyles()
  const { color } = props
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  })
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              <Link to="/">
                <img src={Logo} style={{ width: 100 }} alt="Website logo" />
              </Link>
            </Button>
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};

import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
  cardTitle,
  grayColor,
  tooltip,
} from "assets/jss/material-dashboard-pro-react.js";

const extendedTablesStyle = {
  ...customCheckboxRadioSwitch,
  ...buttonGroupStyle,
  right: {
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
  },
  description: {
    maxWidth: '150px',
  },
  actionButton: {
    margin: '0 0 0 5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px',
    },
  },
  icon: {
    verticalAlign: 'middle',
    width: '17px',
    height: '17px',
    top: '-1px',
    position: 'relative',
  },
  imgContainer: {
    width: '120px',
    maxHeight: '160px',
    overflow: 'hidden',
    display: 'block',
  },
  img: {
    width: '100%',
    height: 'auto',
    verticalAlign: 'middle',
    border: '0',
  },
  tdName: {
    minWidth: '200px',
    fontWeight: '400',
    fontSize: '1.5em',
  },
  tdNameAnchor: {
    color: grayColor[2],
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: '0.75em',
    fontWeight: '300',
  },
  tdNumber: {
    textAlign: 'right',
    minWidth: '145px',
    fontWeight: '300',
    fontSize: '1.3em !important',
  },
  tdNumberSmall: {
    marginRight: '3px',
  },
  tdNumberAndButtonGroup: {
    lineHeight: '1 !important',
  },
  positionAbsolute: {
    position: 'absolute',
    right: '0',
    top: '0',
  },
  customFont: {
    fontSize: '16px !important',
  },
  actionButtonRound: {
    width: 'auto',
    height: 'auto',
    minWidth: 'auto',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  floatRight: {
    float: 'right',
  },
  tooltip,
  positionCheckbox: {
    paddingLeft: '0',
  },
  checkCell: {
    maxWidth: '16px',
  },
  bold: {
    fontWeight: 'bold',
  },
  resultCellHeader: {
    color: '#1a5da2',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '1.1em !important',
  },
  resultSubjectCell: {
    color: '#1a5da2',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  resultCell: {
    color: '#1a5da2',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  overviewItem: {
    marginTop: 5,
    textTransform: 'uppercase',
  },
  title: {
    fontWeight: 'bold',
    backgroundColor: '#1a5da2',
    color: '#fff',
    display: 'inline-block',
    padding: '0 10px 0 10px',
    borderRadius: 5,
  },
  profile: {
    height: 30,
    borderRadius: 10,
    marginRight: 10,
  },
  search: {
    marginRight: 10,
  },
  'download-pdf': {
    cursor: 'pointer',
  },
};

export default extendedTablesStyle;

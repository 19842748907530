import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 10,
    outline: `1px dashed`,
    padding: 2,
  },
}))

export default function DropFileUpload({ children, gridProps, handleFiles }) {
  const classes = useStyles(),
    onDrop = useCallback(acceptedFiles => handleFiles(acceptedFiles), [
      handleFiles,
    ]),
    { getRootProps, getInputProps, isDragActive } = useDropzone({
      noClick: true,
      onDrop,
    })

  return (
    <Grid
      {...getRootProps()}
      {...gridProps}
      className={isDragActive ? classes.root : undefined}
    >
      <input {...getInputProps()} />
      {children}
    </Grid>
  )
}

import moment from "moment";

export const checkInvalid = obj => Object.values(obj).some(value => !value);

export const capitalizeFirst = s =>
         s ? s.charAt(0).toUpperCase() + s.slice(1) : ''

export const checkEmptyObject = obj => {
  if (Object.entries(obj).length === 0)
    throw new Error("Invalid update request");
};

export const checkRequiredFields = (obj, fields) =>
  fields.some(field => !obj[field]);

export const formatDate = date => moment(date).format("YYYY/MM/DD");

export const formatDateFriendly = date => moment(date).format("MMMM Do YYYY");

export const clean = obj => {
  for (var propName in obj) {
    if (!obj[propName]) {
      delete obj[propName];
    }
  }
  return obj;
};

export const getAge = date => moment().diff(date, 'years')

export const seo = (data = {}) => {
  data.title = data.title || 'Sage'
  data.metaDescription =
    data.metaDescription || 'Pushing the frontiers of education'

  document.title = data.title
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', data.metaDescription)
}

export const mapOptions = array => {
  if (!array?.length) return []
  return array.map(value => ({
    label: capitalizeFirst(value),
    value: value.toLowerCase(),
  }))
}

export const getSubdomain = pathname => {
  const sub = pathname.split('/s/')[1]
  return sub.split('/')[0]
}

export const getPaymentCallbackValues = ({ pathname, search }) => {
  let status_page = false

  const pathArray = pathname.split('/'),
    secondToLast = pathArray[pathArray.length - 2],
    last = pathArray[pathArray.length - 1]
  if (secondToLast === 'pay' && last === 'status') status_page = true

  search = search.substr(1)
  let query = ''
  const searchObject = {},
    searchArray = search.split('&')
  searchArray.forEach(item => {
    let [key, value] = item.split('=')
    if (key === 'tx_ref') key = 'ref'
    searchObject[key] = value
    query += `&${key}=${value}`
  })

  if (!status_page) return { status_page }

  return { status_page, query, ...searchObject }
}

export const mapReponseOptions = (array = [], label = 'name', value = 'id') =>
  array?.map(f => ({ ...f, label: f[label], value: f[value] }))

export const getFeeStatus = (fees, type = 'school fees') => {
  const status = 'Unpaid'
  if (!fees || !fees.length) return status
  const schoolFees = fees.find(
    ({ class_fee }) =>
      class_fee?.fee_type?.name?.toLowerCase() === type?.toLowerCase()
  )
  return schoolFees?.status || status
}

export const sumValuesInArray = (obj, arr) =>
  arr.reduce((prev, curr) => prev + Number(obj[curr] || 0), 0)

export const grades = value => {
  if (value < 30) return 'F'
  if (value < 40) return 'E'
  if (value < 50) return 'D'
  if (value < 60) return 'C'
  if (value < 70) return 'B'
  return 'A'
}

export const concatGradeScore = value => value + ' - ' + grades(value)

export const generateArray = (length, data = {}) =>
  [...Array(length).keys()].map(num => ({ ...data, id: num + 1 }))

export const getQueryObject = ({ search }, queryArray) => {
  const searchParams = new URLSearchParams(search);
  let params = {};
  queryArray.forEach((key) => {
    params = { ...params, [key]: searchParams.get(key) };
  });
  return params;
};
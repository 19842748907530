import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';

import ClassPage from '../../../views/Classes/screens/Manage';
import TermPage from '../../../views/Terms/screens/Manage';
import SubjectPage from '../../../views/Subjects/screens/Manage';
import SessionPage from '../../../views/Sessions/screens/Manage';
import { useSelector } from 'react-redux';

export default function Panels() {
  const { terms } = useSelector(state => state.terms);
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <NavPills
            color="rose"
            alignCenter
            tabs={[
              {
                tabButton: 'Classes',
                tabContent: <ClassPage />,
              },
              {
                tabButton: 'Terms',
                tabContent: <TermPage />,
              },
              {
                tabButton: 'Subjects',
                tabContent: <SubjectPage />,
              },
              {
                tabButton: 'Sessions',
                tabContent: <SessionPage />,
              },
            ].filter(({ tabButton }) =>
              tabButton === 'Sessions' ? terms?.length : true
            )}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

// @material-ui/icons
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Clearfix from 'components/Clearfix/Clearfix';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardAvatar from 'components/Card/CardAvatar.js';

// Custom components
import FormDialog from '../../../common/components/FormDialog';
import DropFileUpload from '../../../common/components/DropFileUpload';
import LocalFileUpload from '../../../common/components/LocalFileUpload';

import Person from 'assets/img/person.png';

import {
  getStudent,
  updateStudent,
  deleteStudent,
  getStudentCurrentClass,
  promoteStudent,
  updateFile,
} from '../actions';
import { getCurrentTermClasses } from '../../Classes/actions';
import { capitalizeFirst } from '../../../helpers';
import { IMAGE_BASE } from 'consts';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles';

const useStyles = makeStyles(styles);

function StudentDetails({
  student,
  getStudent,
  update,
  location,
  getClass,
  getClasses,
  current_student_class,
  current_classes,
  promoteStudent,
  updateFile,
}) {
  const { id } = useParams(),
    classes = useStyles(),
    [edit_name, toggleEditName] = React.useState(false),
    [edit_dob, toggleEditDOB] = React.useState(false),
    [gender, toggleEditGender] = React.useState(null),
    [promote_dialog, togglePromoteDialog] = React.useState(false),
    [file, updateFileDialog] = React.useState(null),
    studentName = `${capitalizeFirst(student?.firstname)} ${
      student?.middlename ? capitalizeFirst(student?.middlename) : ""
    } ${capitalizeFirst(student?.lastname)}`;
  React.useEffect(() => {
    getStudent(id);
    getClass(id);
  }, [getStudent, getClass, id]);
  return (
    <div>
      {edit_name && (
        <FormDialog
          title="Update name"
          formFields={{
            firstname: student?.firstname || '',
            middlename: student?.middlename || '',
            lastname: student?.lastname || '',
          }}
          handleClose={() => toggleEditName(false)}
          submit={body =>
            update({ ...body, id, reset: () => toggleEditName(false) })
          }
        />
      )}
      {edit_dob && (
        <FormDialog
          fullWidthDate
          dialogSize="xs"
          title="Update date of birth"
          dateFields={{ dob: new Date(student?.dob) }}
          handleClose={() => toggleEditDOB(false)}
          submit={body =>
            update({ ...body, id, reset: () => toggleEditDOB(false) })
          }
        />
      )}
      {gender && (
        <FormDialog
          title="Update Gender"
          dialogSize="xs"
          selectFields={{
            gender: ['male', 'female'].map(value => ({
              label: capitalizeFirst(value),
              value,
            })),
          }}
          selectValues={{ gender }}
          currentSelectValue={student?.gender?.toLowerCase()}
          handleClose={() => toggleEditGender(false)}
          submit={body =>
            update({ ...body, id, reset: () => toggleEditGender(false) })
          }
        />
      )}
      {promote_dialog && current_classes && (
        <FormDialog
          title="Promote student"
          dialogSize="xs"
          selectFields={{ classes: current_classes }}
          currentSelectValue={current_student_class?.session_term_class?.id}
          handleClose={() => togglePromoteDialog(false)}
          submit={body =>
            promoteStudent({
              ...body,
              id,
              reset: () => togglePromoteDialog(false),
            })
          }
        />
      )}
      {file && (
        <FormDialog
          title={`Update ${Object.keys(file)[0]}`}
          fileFields={file}
          handleClose={() => updateFileDialog(null)}
          submit={body =>
            updateFile({ id, body, reset: () => updateFileDialog(null) })
          }
        />
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card profile>
            <CardAvatar profile>
              <DropFileUpload
                handleFiles={f =>
                  f?.length &&
                  updateFile({ id, body: { profilepicture: f[0] } })
                }
              >
                <LocalFileUpload
                  name="student-profile"
                  onChange={f =>
                    f?.length &&
                    updateFile({ id, body: { profilepicture: f[0] } })
                  }
                  Component={() => (
                    <img
                      alt={studentName}
                      src={
                        student?.profilepicture
                          ? `${IMAGE_BASE}/${student?.profilepicture}`
                          : Person
                      }
                    />
                  )}
                />
              </DropFileUpload>
            </CardAvatar>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography variant="subtitle2">
                    Student ID: <u>{student?.student_id}</u>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Name: <u>{studentName}</u>
                    <Button
                      color="info"
                      className={classes.actionButton}
                      size="sm"
                      justIcon
                      round
                      onClick={() => toggleEditName(true)}
                    >
                      <Edit className={classes.icon} />
                    </Button>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Gender: <u>{student?.gender}</u>
                    <Button
                      color="info"
                      className={classes.actionButton}
                      size="sm"
                      justIcon
                      round
                      onClick={() =>
                        toggleEditGender(student?.gender?.toLowerCase())
                      }
                    >
                      <Edit className={classes.icon} />
                    </Button>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    DOB: <u>{moment(student?.dob).format('MMM Do YYYY')}</u>
                    <Button
                      color="info"
                      className={classes.actionButton}
                      size="sm"
                      justIcon
                      round
                      onClick={() => toggleEditDOB(true)}
                    >
                      <Edit className={classes.icon} />
                    </Button>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Class:{' '}
                    <u>
                      {current_student_class?.session_term_class &&
                        current_student_class?.session_term_class.class?.name}
                    </u>
                    {/* <Button
                      color="success"
                      size="sm"
                      round
                      onClick={() => {
                        if(!current_classes?.length) getClasses()
                        togglePromoteDialog(true)
                      }}
                    >
                      Promote
                    </Button> */}
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    {student?.fees.map(({ class_fee, status }, i) => (
                      <p key={i}>
                        {capitalizeFirst(class_fee?.fee_type?.name)}:
                        <u>{status}</u>
                      </p>
                    ))}
                    {!student?.fees?.length && (
                      <p>
                        SCHOOL FEES:
                        <u>UNPAID</u>
                      </p>
                    )}
                  </Typography>
                  <br />
                  <LocalFileUpload
                    name="student-profile-2"
                    onChange={f =>
                      f?.length &&
                      updateFile({ id, body: { profilepicture: f[0] } })
                    }
                    buttonProps={{
                      color: 'rose',
                      size: 'sm',
                      children: 'update passport',
                    }}
                  />
                  <Button color="info" size="sm">
                    <Link
                      color="inherit"
                      href={`/admin/students/scores/${current_student_class?.session_term_class?.id}/${id}`}
                    >
                      Manage Scores
                    </Link>
                  </Button>
                  <Button color="success" size="sm">
                    <Link
                      underline="none"
                      color="inherit"
                      href={`/admin/results/${current_student_class?.session_term_class?.id}/${id}`}
                    >
                      View Result
                    </Link>
                  </Button>
                  {/* <Button
                    color="danger"
                    size="sm"
                    onClick={() => console.log('DELETE')}
                  >
                    Delete Student
                  </Button> */}
                </GridItem>
              </GridContainer>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  getStudent: id => dispatch(getStudent(id)),
  update: body => dispatch(updateStudent(body)),
  delete: id => dispatch(deleteStudent(id)),
  getClass: id => dispatch(getStudentCurrentClass(id)),
  promoteStudent: body => dispatch(promoteStudent(body)),
  updateFile: body => dispatch(updateFile(body)),
  getClasses: () => dispatch(getCurrentTermClasses()),
});

const mapStateToProps = state => ({
  ...state.students,
  current_classes: state.classes.current_term_classes?.map(item => ({
    value: item.id,
    label: item['class.name'],
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetails);

const isProd = process.env.REACT_APP_ENV === 'production'
export const BACKEND = isProd
  ? 'https://schoolman-api.herokuapp.com'
  : 'http://localhost:8001'
export const BACKEND_API = `${BACKEND}/api`

export const IMAGE_BASE = `${BACKEND}/auth/image`

export const payment_responses = {
  successful:
    'Your payment was successful. Check your email for your payment receipt',
  failed:
    'Your payment failed. Kindly try again later or contact us for investigations.',
}
import axios from "axios";
import { actions } from "./redux";
import { BACKEND_API } from "consts";
import { checkRequiredFields } from "../../helpers";
import {
  CSVFormatError,
  successfulCreateRequest,
  successfulUpdateRequest,
  successfulDeleteRequest,
} from "../../messages";
import { actions as commonActions } from "../../common/redux";
const {
  showNotification,
  toggleErrorAlert,
  setLoading,
  cancelConfirmation,
} = commonActions;

export const getTerms = () => dispatch =>
  axios
    .get(`${BACKEND_API}/terms`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: "terms", value: data.data }));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });

export const getTerm = () => dispatch =>
  axios
    .get(`${BACKEND_API}/profile/term`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'term', value: data.data }))
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })

export const createTerm = body => dispatch => {
  dispatch(setLoading(true));
  return axios
    .post(`${BACKEND_API}/terms`, body)
    .then(() => {
      dispatch(getTerms());
      dispatch(showNotification({ message: successfulCreateRequest("Term") }));
      dispatch(
        actions.updateField({ key: "open_create_dialog", value: false })
      );
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const editTerm = ({ id, name }) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/terms/${id}`, { name })
    .then(() => {
      dispatch(getTerms());
      dispatch(showNotification({ message: successfulUpdateRequest("Term") }));
      dispatch(actions.updateField({ key: "open_edit_dialog", value: false }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const deleteTerm = id => dispatch => {
  dispatch(setLoading(true));
  return axios
    .delete(`${BACKEND_API}/terms/${id}`)
    .then(() => {
      dispatch(getTerms());
      dispatch(showNotification({ message: successfulDeleteRequest("Term") }));
      dispatch(cancelConfirmation());
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const createTerms = terms => dispatch => {
  dispatch(setLoading(true));
  const isInvalid = terms.some(item => checkRequiredFields(item, ["name"]));
  if (isInvalid)
    return dispatch(toggleErrorAlert(CSVFormatError()));
  return axios
    .post(`${BACKEND_API}/terms/bulk`, { terms })
    .then(() => {
      dispatch(getTerms());
      dispatch(showNotification({ message: successfulCreateRequest("Terms") }));
      dispatch(actions.updateField({ key: "open_csv_dialog", value: false }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

import axios from 'axios'
import { actions } from './redux'
import { BACKEND_API } from 'consts'
import { actions as commonActions } from '../../common/redux'
const {
  toggleErrorAlert,
} = commonActions

export const getResultInfo = (class_id, student_id) => dispatch =>
  axios
    .get(`${BACKEND_API}/students/result/${class_id}/${student_id}`)
    .then(({ data }) => {
      dispatch(
        actions.updateField({ key: 'result_info', value: data.data })
      )
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })

export const getScores = (class_id, student_id) => dispatch =>
  axios
    .get(`${BACKEND_API}/scores/${class_id}/${student_id}`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'scores', value: data.data }))
      let total_score = 0
      let passed = 0
      data.data.forEach(({ total }) => {
        total_score += total
        if (total > 39) passed += 1
      })
      dispatch(
        actions.updateField({
          key: 'stats',
          value: {
            obtained: total_score,
            passed,
            average: (total_score / data.data.length).toFixed(1),
          },
        })
      )
    })
    .catch(error => {
      toggleErrorAlert(error.response?.data.errors.message || error.message)
    })
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import Button from 'home-components/CustomButtons/Button.js'

import headersStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js'

const useStyles = makeStyles(headersStyle)

const token = localStorage.getItem('token')

const HeaderLinks = ({ is_home }) => {
  const classes = useStyles()
  return (
    <div className={classes.collapse}>
      <List className={classes.list + ' ' + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          <Button
            href="#home"
            className={classes.navLink}
            onClick={e => e.preventDefault()}
            color="transparent"
          >
            Home
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="#about"
            className={classes.navLink}
            onClick={e => e.preventDefault()}
            color="transparent"
          >
            About us
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="#products"
            className={classes.navLink}
            onClick={e => e.preventDefault()}
            color="transparent"
          >
            Products
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="#contact"
            className={classes.navLink}
            onClick={e => e.preventDefault()}
            color="transparent"
          >
            Contact us
          </Button>
        </ListItem>
      </List>
      <List className={classes.list + ' ' + classes.mlAuto}>
        {token ? (
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              href="/admin/dashboard"
              target="_blank"
              className={classes.navLink + ' ' + classes.navLinkJustIcon}
            >
              Dashboard
            </Button>
          </ListItem>
        ) : (
          <>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                href="/auth/register"
                target="_blank"
                className={classes.navLink + ' ' + classes.navLinkJustIcon}
              >
                Register
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                href="/auth/login"
                target="_blank"
                className={classes.navLink + ' ' + classes.navLinkJustIcon}
              >
                Login
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </div>
  )
}

export default HeaderLinks

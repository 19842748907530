import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles'
import { capitalizeFirst } from 'helpers'
import { IMAGE_BASE } from 'consts'

const useStyles = makeStyles(styles)

function ProfileRow({
  title,
  field,
  isImage,
  updateField,
  imageHeight = 168,
  bank = {},
}) {
  const classes = useStyles(),
    value = bank && bank[field]
  return (
    <GridContainer>
      <GridItem sm={2}>
        <h4 className={classes.bold}>{capitalizeFirst(title || field)}:</h4>
      </GridItem>
      <GridItem sm={10}>
        <GridContainer className={classes.flexStart}>
          {isImage ? (
            <img
              alt={`school ${field}`}
              src={`${IMAGE_BASE}/${value}`}
              style={{
                height: imageHeight,
              }}
            />
          ) : (
            <h4 className={classes.mr10}>{capitalizeFirst(value)}</h4>
          )}
          <IconButton
            onClick={() => updateField({ [field]: isImage ? '' : value })}
          >
            <EditIcon />
          </IconButton>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

export default ProfileRow

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form: {
    name: '',
  },
  classInfo: null,
  term_classes: [],
  term_class: null,
  term_class_id: null,
  term_class_students: null,
  current_term_classes: null,
  loading: false,
  open_create_dialog: false,
  open_edit_dialog: false,
  open_csv_dialog: false,
  filters: {
    class_id: null,
  },
}
export const classSlice = createSlice({
         name: 'class',
         initialState,
         reducers: {
           updateField: (state, { payload }) => {
             state[payload.key] = payload.value
           },
           updateForm: (state, action) => {
             state.form = {
               ...state.form,
               [action.field]: action.value,
             }
           },
           updateFilter: (state, { payload }) => {
             if (!payload || payload?.reset) {
               state.filters = initialState.filters
             }
             if (typeof payload === 'object')
               state.filters = {
                 ...state.filters,
                 ...payload,
               }
           },
         },
       })
export const actions = classSlice.actions

export default classSlice.reducer;

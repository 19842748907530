import axios from 'axios'
import { actions } from './redux'
import { BACKEND_API } from 'consts'
import { checkRequiredFields } from '../../helpers'
import {
  CSVFormatError,
  successfulCreateRequest,
  successfulUpdateRequest,
  successfulDeleteRequest,
} from '../../messages'
import { actions as commonActions } from '../../common/redux'
const {
  showNotification,
  toggleErrorAlert,
  setLoading,
  cancelConfirmation,
} = commonActions

export const getTeachers = () => dispatch =>
  axios
    .get(`${BACKEND_API}/accounts?type=4`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'teachers', value: data.data }))
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })
export const createTeacher = ({ reset, ...body }) => dispatch => {
  dispatch(setLoading(true))
  return axios
    .post(`${BACKEND_API}/accounts`, { ...body, role_id: 4 })
    .then(() => {
      dispatch(getTeachers())
      dispatch(
        showNotification({ message: successfulCreateRequest('Teacher') })
      )
      reset()
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })
}

export const editTeacher = ({ id, reset, ...body }) => dispatch => {
  dispatch(setLoading(true))
  return axios
    .put(`${BACKEND_API}/accounts/${id}`, body)
    .then(() => {
      dispatch(getTeachers())
      dispatch(
        showNotification({ message: successfulUpdateRequest('Teacher') })
      )
      reset()
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })
}

export const deleteTeacher = id => dispatch => {
  dispatch(setLoading(true))
  return axios
    .delete(`${BACKEND_API}/accounts/${id}`)
    .then(() => {
      dispatch(getTeachers())
      dispatch(
        showNotification({ message: successfulDeleteRequest('Teacher') })
      )
      dispatch(cancelConfirmation())
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })
}

export const createTeachers = teachers => dispatch => {
  dispatch(setLoading(true))
  const isInvalid = teachers.some(item => checkRequiredFields(item, ['name']))
  if (isInvalid) return dispatch(toggleErrorAlert(CSVFormatError()))
  return axios
    .post(`${BACKEND_API}/accounts/bulk`, { teachers })
    .then(() => {
      dispatch(getTeachers())
      dispatch(
        showNotification({ message: successfulCreateRequest('Teachers') })
      )
      dispatch(actions.updateField({ key: 'open_csv_dialog', value: false }))
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })
}

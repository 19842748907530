import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "views/Auth/redux";
import common from "common/redux";
import profile from "views/Profile/redux";
import classes from "views/Classes/redux";
import terms from "views/Terms/redux";
import subjects from "views/Subjects/redux";
import sessions from "views/Sessions/redux";
import students from "./views/Students/redux";
import results from "./views/Result/redux";
import teachers from './views/Teachers/redux'
import school from './views/School/redux'
import fees from './views/Fees/redux'

export default function rootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    auth,
    common,
    profile,
    classes,
    terms,
    subjects,
    sessions,
    students,
    results,
    teachers,
    school,
    fees,
  })
}

import React from "react";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity'
import EditIcon from '@material-ui/icons/Edit'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Clearfix from 'components/Clearfix/Clearfix'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import FormDialog from '../../../common/components/FormDialog'
import ProfileRow from '../components/ProfileRow'

import { getSchoolProfile, updateProfile, updateFile } from '../actions';
import { capitalizeFirst, mapOptions } from 'helpers'

import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles'
import countries from 'countries'

const useStyles = makeStyles(styles)

function UserProfile({ school, getSchoolProfile, update, updateFile }) {
  const classes = useStyles(),
    [open_owner_dialog, updateOwnerDialog] = React.useState(false),
    [field, updateFieldDialog] = React.useState(null),
    [country_dialog, updateCountryDialog] = React.useState(false),
    [file, updateFileDialog] = React.useState(null);
  React.useEffect(() => {
    getSchoolProfile();
  }, [getSchoolProfile]);
  return (
    <div>
      {open_owner_dialog && (
        <FormDialog
          title="Update Proprietor"
          formFields={{
            firstname: school?.owner?.firstname,
            lastname: school?.owner?.lastname,
          }}
          selectFields={{
            title: mapOptions(['mr', 'mrs', 'miss']),
          }}
          selectValues={{ title: school?.owner?.title }}
          handleClose={() => updateOwnerDialog(false)}
          submit={body =>
            update({ body, reset: () => updateOwnerDialog(false) })
          }
        />
      )}
      {country_dialog && (
        <FormDialog
          title="Update country"
          selectFields={{
            country: mapOptions(countries),
          }}
          selectValues={{ country: school?.country }}
          handleClose={() => updateCountryDialog(false)}
          submit={body =>
            update({ body, reset: () => updateCountryDialog(false) })
          }
        />
      )}
      {field && (
        <FormDialog
          title={`Update ${Object.keys(field)[0]}`}
          formFields={field}
          handleClose={() => updateFieldDialog(null)}
          submit={body =>
            update({ body, reset: () => updateFieldDialog(null) })
          }
        />
      )}
      {file && (
        <FormDialog
          title={`Update ${Object.keys(file)[0]}`}
          fileFields={file}
          handleClose={() => updateFileDialog(null)}
          submit={body =>
            updateFile({ body, reset: () => updateFileDialog(null) })
          }
        />
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Edit Profile - <small>Complete your profile</small>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem sm={2}>
                      <h4 className={classes.bold}>Proprietor:</h4>
                    </GridItem>
                    <GridItem sm={10}>
                      <GridContainer>
                        <h4 className={classes.mr10}>
                          {capitalizeFirst(school?.owner?.title)}
                        </h4>
                        <h4 className={classes.mr10}>
                          {capitalizeFirst(school?.owner?.firstname)}
                        </h4>
                        <h4 className={classes.mr10}>
                          {capitalizeFirst(school?.owner?.lastname)}
                        </h4>
                        <IconButton onClick={() => updateOwnerDialog(true)}>
                          <EditIcon />
                        </IconButton>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <ProfileRow
                    field="subdomain"
                    bank={school}
                    updateField={updateFieldDialog}
                  />
                  <ProfileRow
                    field="about"
                    bank={school}
                    updateField={updateFieldDialog}
                  />
                  <ProfileRow
                    field="address"
                    bank={school}
                    updateField={updateFieldDialog}
                  />
                  <ProfileRow
                    field="country"
                    bank={school}
                    updateField={() => updateCountryDialog(true)}
                  />
                  <ProfileRow
                    isImage
                    field="logo"
                    bank={school}
                    updateField={updateFileDialog}
                  />
                  <ProfileRow
                    isImage
                    field="banner"
                    bank={school}
                    updateField={updateFileDialog}
                  />
                </GridItem>
              </GridContainer>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  getSchoolProfile: () => dispatch(getSchoolProfile()),
  update: body => dispatch(updateProfile(body)),
  updateFile: body => dispatch(updateFile(body)),
});

const mapStateToProps = state => ({
  ...state.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

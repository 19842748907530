import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { GreenButton, SimpleButton } from './Buttons'

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
  },
}))

const CustomDialog = ({
  title,
  children,
  handleClose,
  submit,
  size = 'md',
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={size}
    >
      {title && (
        <DialogTitle id="form-dialog-title" classes={{ root: classes.text }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {submit && (
        <DialogActions>
          <SimpleButton onClick={handleClose} label="Cancel" />
          <GreenButton onClick={submit} label="Save" />
        </DialogActions>
      )}
    </Dialog>
  )
}

export default CustomDialog

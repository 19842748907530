import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "home-components/Card/Card.js";
import CardHeader from "home-components/Card/CardHeader.js";
import CardBody from "home-components/Card/CardBody.js";
import CardFooter from "home-components/Card/CardFooter.js";
import CustomInput from "home-components/CustomInput/CustomInput.js";
import Button from "home-components/CustomButtons/Button.js";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import { actions } from "../redux";
import { verifyPin } from "../actions";

const useStyles = makeStyles(contactsStyle);

function ResultSection({
  school,
  check_result,
  is_pin_valid,
  updateCheckResult,
  verifyPin,
}) {
  const navigate = useNavigate();
  const classes = useStyles(),
    { pin, student_id } = check_result,
    onChange = ({ target: { name, value } }) =>
      updateCheckResult({ [name]: value });

  useEffect(() => {
    if (!is_pin_valid) return;
    navigate(`/s/${school.subdomain}/result?pin=${pin}&student=${student_id}`);
  }, [is_pin_valid, student_id, school.subdomain, pin]);

  return (
    <Card className={classes.card1}>
      <form>
        <CardHeader contact color="primary" className={classes.textCenter}>
          <h4 className={classes.cardTitle}>Check result</h4>
        </CardHeader>
        <CardBody>
          <CustomInput
            labelText="Student ID"
            id="student_id"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "student_id",
              value: student_id,
              onChange,
            }}
          />
          <CustomInput
            labelText="Pin"
            id="pin"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "pin",
              value: pin,
              onChange,
            }}
          />
        </CardBody>
        <CardFooter className={classes.justifyContentBetween}>
          <Button
            disabled={!pin || !student_id}
            color="primary"
            className={classes.pullRight}
            onClick={verifyPin}
          >
            Check result
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
}
const mapStateToProps = (state) => state.school;

const mapDispatchToProps = (dispatch) => ({
  updateCheckResult: (body) => dispatch(actions.updateCheckResult(body)),
  verifyPin: () => dispatch(verifyPin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSection);

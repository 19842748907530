import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { TailSpin } from "react-loading-icons";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import PhoneIcon from "@material-ui/icons/Phone";
import Group from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import ReplayIcon from "@material-ui/icons/Replay";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import InfoArea from "components/InfoArea/InfoArea";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

// components methods
import { register } from "../actions";
import { checkInvalid } from "helpers";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);

function RegisterPage({ register }) {
  const classes = useStyles(),
    state = {
      name: "",
      email: "",
      phone: "",
      password: "",
      repeat_password: "",
      checked: false,
    },
    [form, setForm] = React.useState(state),
    [loading, setLoading] = React.useState(false),
    { name, email, phone, password, repeat_password, checked } = form,
    handleChange = ({ name, value }) => {
      setForm({
        ...form,
        [name]: value,
      });
    };
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title="Excellent"
                    description="We have created the perfect management platform. Create a school portal in less than 5 minutes"
                    icon={Timeline}
                    iconColor="rose"
                  />
                  <InfoArea
                    title="Simple"
                    description="Manage your school, migrate to the cloud and increase your reach."
                    icon={Group}
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <form className={classes.form}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        value: name,
                        name: 'name',
                        onChange: e => handleChange(e.target),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <SchoolIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        placeholder: 'School name',
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        value: email,
                        name: 'email',
                        onChange: e => handleChange(e.target),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Email',
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        value: phone,
                        name: 'phone',
                        onChange: e => handleChange(e.target),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <PhoneIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Phone',
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        value: password,
                        name: 'password',
                        onChange: e => handleChange(e.target),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        placeholder: 'Password',
                        type: 'password',
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        value: repeat_password,
                        name: 'repeat_password',
                        onChange: e => handleChange(e.target),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <ReplayIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        placeholder: 'Repeat password',
                        type: 'password',
                      }}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={e =>
                            handleChange({ name: 'checked', value: !checked })
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span>
                          I agree to the{' '}
                          <a href="#pablo">terms and conditions</a>.
                        </span>
                      }
                    />
                    <div className={classes.center}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Button
                            disabled={
                              loading ||
                              checkInvalid(form) ||
                              password !== repeat_password
                            }
                            round
                            color="primary"
                            onClick={() => {
                              setLoading(true)
                              const {
                                loading,
                                repeat_password,
                                checked,
                                ...data
                              } = form
                              register(data, () => setLoading(false))
                            }}
                          >
                            Get started
                            {'  '}
                            {loading && (
                              <TailSpin
                                stroke="#fff"
                                className={{
                                  ...classes.icons,
                                  marginLeft: '1em',
                                }}
                              />
                            )}
                          </Button>
                        </GridItem>
                        <GridItem xs={12}>
                          <Link to="/auth/login">
                            <Button color="rose" simple size="lg" block>
                              Already have an account? Login
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  register: (data, reset) => dispatch(register(data, reset)),
});

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);

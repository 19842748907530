import axios from "axios";
import { actions } from "./redux";
import { BACKEND_API } from "consts";
import { checkRequiredFields } from "../../helpers";
import {
  CSVFormatError,
  successfulCreateRequest,
  successfulUpdateRequest,
  successfulDeleteRequest,
} from "../../messages";
import { actions as commonActions } from "../../common/redux";
const {
  showNotification,
  toggleErrorAlert,
  setLoading,
  cancelConfirmation,
} = commonActions;

export const getSubjects = () => dispatch =>
  axios
    .get(`${BACKEND_API}/subjects`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: "subjects", value: data.data }));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
export const createSubject = body => dispatch => {
  dispatch(setLoading(true));
  return axios
    .post(`${BACKEND_API}/subjects`, body)
    .then(() => {
      dispatch(getSubjects());
      dispatch(
        showNotification({ message: successfulCreateRequest("Subject") })
      );
      dispatch(
        actions.updateField({ key: "open_create_dialog", value: false })
      );
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const editSubject = ({ id, name }) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/subjects/${id}`, { name })
    .then(() => {
      dispatch(getSubjects());
      dispatch(
        showNotification({ message: successfulUpdateRequest("Subject") })
      );
      dispatch(actions.updateField({ key: "open_edit_dialog", value: false }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const deleteSubject = id => dispatch => {
  dispatch(setLoading(true));
  return axios
    .delete(`${BACKEND_API}/subjects/${id}`)
    .then(() => {
      dispatch(getSubjects());
      dispatch(
        showNotification({ message: successfulDeleteRequest("Subject") })
      );
      dispatch(cancelConfirmation());
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const createSubjects = subjects => dispatch => {
  dispatch(setLoading(true));
  const isInvalid = subjects.some(item => checkRequiredFields(item, ["name"]));
  if (isInvalid) return dispatch(toggleErrorAlert(CSVFormatError()));
  return axios
    .post(`${BACKEND_API}/subjects/bulk`, { subjects })
    .then(() => {
      dispatch(getSubjects());
      dispatch(
        showNotification({ message: successfulCreateRequest("Subjects") })
      );
      dispatch(actions.updateField({ key: "open_csv_dialog", value: false }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

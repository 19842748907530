import React from 'react'
import axios from 'axios'
import { createBrowserHistory } from 'history'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import AuthLayout from "layouts/Auth";
import HomeLayout from "layouts/HomeLayout";
import AdminLayout from "layouts/Admin";
import SchoolLayout from "layouts/School";
import ErrorPage from "views/ErrorPage/ErrorPage";
import rootReducer from "./reducers";
import Loader from "common/components/Loader";
import ErrorDialog from "common/components/ErrorDialog";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import Notification from "common/components/Notification";

import "assets/css/material-dashboard-pro-react.css";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const hist = createBrowserHistory();

const store = configureStore({
  reducer: rootReducer(hist),
});

export default function () {
  return (
    <Provider store={store}>
      <Router history={hist}>
        <Loader />
        <ErrorDialog />
        <ConfirmationDialog />
        <Notification />
        <Routes>
          <Route path="/auth/*" element={<AuthLayout />} />
          {token && <Route path="/admin/*" element={<AdminLayout />} />}
          <Route path="/s/*" element={<SchoolLayout />} />
          <Route path="/home" element={<HomeLayout />} />
          <Route
            path="/"
            element={
              <Navigate to={`${token ? "/admin/dashboard" : "/home"}`} />
            }
          />
          <Route element={<ErrorPage />} />
        </Routes>
      </Router>
    </Provider>
  );
}

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Table from 'components/Table/Table';
import Document from './Document';
import Page from './Page';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';

import Logo from 'assets/img/logoo.png';
import Person from 'assets/img/person.png';

import { capitalizeFirst, formatDate, getAge, grades } from 'helpers';
import { IMAGE_BASE } from 'consts';

const useStyles = makeStyles(styles);

const OverviewItem = ({ name, value }) => {
  const classes = useStyles();
  return (
    <GridItem xs={4} className={classes.overviewItem}>
      <b>{name}</b>: {value}
    </GridItem>
  );
};

const RemarkItem = ({ name, value }) => {
  const classes = useStyles();
  return (
    <GridItem xs={12} className={classes.overviewItem}>
      <b>{name}</b>: {value}
    </GridItem>
  );
};

export default function CResult({
  scores,
  result_info,
  stats,
  school,
  term_class,
  student_id,
  pdfMode = false,
}) {
  const classes = useStyles();
  const {
      class_detail,
      total_students,
      position,
      student: { firstname, lastname, middlename, dob, profilepicture },
      comments,
    } = result_info,
    { average, passed, obtained } = stats;

  return (
    <Document pdfMode={pdfMode}>
      <Page className="invoice-wrapper" pdfMode={pdfMode}>
        <Card style={{ color: '#1a5da2' }}>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <GridContainer
                  style={{
                    textAlign: 'center',
                    marginBottom: 20,
                  }}
                >
                  <GridItem xs={3}>
                    <img
                      src={
                        school?.logo ? `${IMAGE_BASE}/${school?.logo}` : Logo
                      }
                      alt="school logo"
                      style={{
                        height: 168,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={9}>
                    <Typography variant="h2" className={classes.bold}>
                      {school?.name?.toUpperCase()}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.bold}>
                      {school?.address}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.bold}>
                      GOVERNMENT APPROVED
                    </Typography>
                    <Typography variant="h6" className={classes.title}>
                      INDIVIDUAL STUDENT ASSESSMENT SHEET
                    </Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={10}>
                <GridContainer>
                  <OverviewItem
                    name="NAME"
                    value={`${firstname} ${
                      middlename ? middlename : ''
                    } ${lastname}`}
                  />
                  <OverviewItem name="CODE" value={student_id} />
                  <OverviewItem name="AGE" value={getAge(dob)} />
                </GridContainer>
                <GridContainer>
                  <OverviewItem
                    name="CLASS"
                    value={class_detail['class.name']}
                  />
                  <OverviewItem
                    name="SESSION"
                    value={`${
                      formatDate(
                        class_detail['session_term.session.start']
                      )?.split('/')[0]
                    }/${
                      formatDate(
                        class_detail['session_term.session.end']
                      ).split('/')[0]
                    }`}
                  />
                  <OverviewItem
                    name="TERM"
                    value={class_detail['session_term.term.name']}
                  />
                </GridContainer>
                <GridContainer>
                  <OverviewItem
                    name="POSITION"
                    value={position && position[0] && position[0].rank}
                  />
                  <OverviewItem name="NO IN CLASS" value={total_students} />
                  <OverviewItem name="NO OF SUBJECTS PASSED" value={passed} />
                </GridContainer>
                <GridContainer>
                  <OverviewItem
                    name="AVERAGE PERCENTAGE"
                    value={`${average}%`}
                  />
                  <OverviewItem name="TOTAL OBTAINED" value={obtained} />
                  <OverviewItem
                    name="TOTAL OBTAINABLE"
                    value={scores?.length * 100}
                  />
                </GridContainer>
              </GridItem>
              <GridItem xs={2} style={{}}>
                <img
                  src={
                    profilepicture ? `${IMAGE_BASE}/${profilepicture}` : Person
                  }
                  alt="Student's profile"
                  style={{
                    height: 70,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                {!!scores?.length && (
                  <Table
                    tableHead={[
                      '',
                      'Test 1',
                      'Test 2',
                      'CA',
                      'Exam',
                      'Total',
                      'Grade',
                      'Position',
                    ]}
                    tableData={scores?.map(score => [
                      score['subject.name'],
                      score.first_test,
                      score.second_test,
                      score.continuous_assessment,
                      score.exam,
                      score.total,
                      grades(score.total),
                      score.position,
                    ])}
                    customHeadCellClasses={[
                      classes.resultCellHeader,
                      classes.resultCellHeader,
                      classes.resultCellHeader,
                      classes.resultCellHeader,
                      classes.resultCellHeader,
                      classes.resultCellHeader,
                      classes.resultCellHeader,
                      classes.resultCellHeader,
                    ]}
                    customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                    customCellClasses={[
                      classes.resultSubjectCell,
                      classes.resultCell,
                      classes.resultCell,
                      classes.resultCell,
                      classes.resultCell,
                      classes.resultCell,
                      classes.resultCell,
                      classes.resultCell,
                    ]}
                    customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                  />
                )}
              </GridItem>
              <GridItem xs={12}>
                <GridContainer>
                  <RemarkItem
                    name="Class Teacher's Remark"
                    value={comments?.teachers_comment}
                  />
                  <RemarkItem
                    name="Class Teacher's Name & Signature"
                    value={
                      term_class ? (
                        <u>{`${capitalizeFirst(
                          term_class['account.title']
                        )} ${capitalizeFirst(
                          term_class['account.firstname']
                        )} ${capitalizeFirst(
                          term_class['account.lastname']
                        )}`}</u>
                      ) : (
                        ''
                      )
                    }
                  />
                  <RemarkItem
                    name="Principal's Remark"
                    value={comments?.principals_comment}
                  />
                  <RemarkItem
                    name="Principal's Name & Signature"
                    value={`${capitalizeFirst(
                      school?.owner?.title
                    )} ${capitalizeFirst(
                      school?.owner?.firstname
                    )} ${capitalizeFirst(school?.owner?.lastname)}`}
                  />
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Page>
    </Document>
  );
}

import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from 'components/Table/Table';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormDialog from '../../../common/components/FormDialog';
import MenuButton from '../../../common/components/MenuButton';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';

import {
  getSubjects,
  editSubject,
  createSubject,
  deleteSubject,
  createSubjects,
} from '../actions';
import { actions } from '../redux';
import { actions as commonActions } from '../../../common/redux';
import { capitalizeFirst } from 'helpers';

const useStyles = makeStyles(styles);

function ManageSubjects({
  getSubjects,
  subjects,
  open_create_dialog,
  open_edit_dialog,
  open_csv_dialog,
  toggleDialog,
  createSubject,
  editSubject,
  deleteSubject,
  createSubjects,
}) {
  const [checked, setChecked] = React.useState([]),
    handleToggle = value => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    },
    classes = useStyles(),
    [id, updateId] = React.useState(null),
    [name, updateName] = React.useState(''),
    handleEdit = (id, name) => {
      updateId(id);
      updateName(name);
      toggleDialog('open_edit_dialog', true);
    },
    handleDelete = id => deleteSubject(id),
    fillButtons = (id, name) =>
      [
        { color: 'info', icon: Edit, func: () => handleEdit(id, name) },
        { color: 'danger', icon: Close, func: () => handleDelete(id) },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
  React.useEffect(() => {
    getSubjects();
  }, [getSubjects]);
  return (
    <GridContainer>
      {open_create_dialog && (
        <FormDialog
          title="Create subject"
          formFields={{ name: '' }}
          handleClose={() => toggleDialog('open_create_dialog', false)}
          submit={create => createSubject(create)}
        />
      )}
      {open_edit_dialog && (
        <FormDialog
          title="Edit subject"
          formFields={{ name }}
          handleClose={() => toggleDialog('open_edit_dialog', false)}
          submit={update => editSubject({ id, ...update })}
        />
      )}
      {open_csv_dialog && (
        <FormDialog
          title="Create subjects from csv file"
          csvFields={['name']}
          filename="subjects"
          csvSampleDatabase={['english', 'mathematics'].map(name => ({ name }))}
          handleClose={() => toggleDialog('open_csv_dialog', false)}
          submit={body => createSubjects(body)}
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <div className={classes.cardContentRight}>
                  <MenuButton
                    title="Create"
                    class_name="floatRight"
                    buttons={[
                      {
                        name: 'Create One',
                        handler: () => toggleDialog('open_create_dialog', true),
                      },
                      {
                        name: 'Upload CSV',
                        handler: () => toggleDialog('open_csv_dialog', true),
                      },
                    ]}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            {!!subjects?.length ? (
              <Table
                tableHead={['', 'Name', '']}
                tableData={subjects?.map(({ name, id }) => [
                  <Checkbox
                    key="key"
                    className={classes.positionAbsolute}
                    tabIndex={-1}
                    onClick={() => handleToggle(id)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />,
                  capitalizeFirst(name),
                  fillButtons(id, name),
                ])}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            ) : (
              <Typography variant="body2">
                Click on the create button to create school subjects
                <br />
                Contact us if you are having any difficulties.
              </Typography>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapDispatchToProps = dispatch => ({
  getSubjects: () => dispatch(getSubjects()),
  createSubject: body => dispatch(createSubject(body)),
  createSubjects: body => dispatch(createSubjects(body)),
  editSubject: body => dispatch(editSubject(body)),
  deleteSubject: id =>
    dispatch(
      commonActions.showConfirmation({
        title: 'Delete subject',
        message: 'delete subject',
        open: true,
        handler: () => deleteSubject(id)(dispatch),
      })
    ),
  toggleDialog: (key, open) =>
    dispatch(actions.updateField({ key, value: open })),
});

const mapStateToProps = state => ({
  ...state.subjects,
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageSubjects);

/*eslint-disable*/
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "home-components/Grid/GridContainer.js";
import GridItem from "home-components/Grid/GridItem.js";
import Parallax from "home-components/Parallax/Parallax.js";

import About from "./Sections/About.js";
import Contact from "./Sections/Contact.js";
import Activities from "./Sections/Activities.js";
import PaymentStatus from "./Sections/PaymentStatus.js";

import componentsStyle from "assets/jss/material-kit-pro-react/views/componentsStyle.js";

import BG from "assets/img/bg5.jpg";
import { verifyPayment } from "./actions.js";
import { IMAGE_BASE } from "consts.js";
import { getQueryObject } from "helpers.js";

const useStyles = makeStyles(componentsStyle);

export default function SchoolPage({ school }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const {
    status,
    transaction_id,
    tx_ref: ref,
  } = getQueryObject(useLocation(), ["status", "tx_ref", "transaction_id"]);

  const status_page =
    status && ref && transaction_id && params["*"].includes("pay/status");

  const verify = useCallback(
    () => dispatch(verifyPayment({ status, ref, transaction_id })),
    [],
  );

  useEffect(() => {
    if (!status_page) return;
    verify();
  }, [status_page]);

  return (
    <div>
      <Parallax
        image={school?.banner ? `${IMAGE_BASE}/${school.banner}` : BG}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1>{school?.name}</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        {status_page ? <PaymentStatus /> : <About />}
      </div>
      {!status_page && <Activities />}
      <Contact />
    </div>
  );
}

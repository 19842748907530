import React from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";

// material-ui icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormDialog from "../../../common/components/FormDialog";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import { getTermClasses, deleteTermClass, createTermClass } from "../actions";
import { getCurrentSessionTerm } from "../../Profile/actions";
import { actions } from "../redux";
import { actions as commonActions } from "../../../common/redux";
import { formatDate } from "../../../helpers";

const useStyles = makeStyles(styles);

function TermClasses({
  current,
  getCurrentSessionTerm,
  getClasses,
  term_classes,
  deleteClass,
  createClass,
  toggleDialog,
  open_create_dialog,
  session_term,
}) {
  const classes = useStyles(),
    handleDelete = id => deleteClass(id),
    fillButtons = id =>
      [
        {
          name: 'Delete class',
          color: 'danger',
          icon: Close,
          func: () => handleDelete(id),
        },
      ].map((prop, key) => {
        return (
          <Tooltip
            id={`tooltip-top-${key}`}
            title={prop.name}
            key={key}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color={prop.color}
              className={classes.actionButton}
              onClick={prop.func}
            >
              <prop.icon className={classes.icon} />
            </Button>
          </Tooltip>
        )
      })
  React.useEffect(() => {
    getCurrentSessionTerm()
    getClasses()
  }, [getCurrentSessionTerm, getClasses])
  return (
    <GridContainer>
      {open_create_dialog && (
        <FormDialog
          title="Create class"
          formFields={{ name: '' }}
          handleClose={() => toggleDialog('open_create_dialog', false)}
          submit={create => createClass(create)}
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={9}>
                <Typography variant="subtitle2">
                  Session:{' '}
                  <u>
                    {current?.session
                      ? `${formatDate(current?.session.start)?.split('/')[0]}/${
                          formatDate(current?.session.end).split('/')[0]
                        }`
                      : 'No session created'}
                  </u>
                </Typography>
                <Typography variant="subtitle2">
                  Term:{' '}
                  <u>
                    {current?.term?.name
                      ? current?.term?.name
                      : 'No term selected'}
                  </u>{' '}
                </Typography>
              </GridItem>
              <GridItem xs={3}>
                <div className={classes.cardContentRight}>
                  {session_term?.current_term && (
                    <Button
                      onClick={() => toggleDialog('open_create_dialog', true)}
                      className={classes.floatRight}
                      color="success"
                      aria-controls="fade-menu"
                    >
                      New class
                    </Button>
                  )}
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            {term_classes?.length ? (
              <Table
                tableHead={['Name', '']}
                tableData={term_classes?.map(data => [
                  <Link href={`/admin/class/${data.id}`}>
                    {data['class.name']}
                  </Link>,
                  fillButtons(data.id),
                ])}
                customCellClasses={[classes.left, classes.right]}
                customClassesForCells={[0, 5]}
                customHeadCellClasses={[classes.left, classes.right]}
                customHeadClassesForCells={[0, 5]}
              />
            ) : (
              <Typography variant="body2">
                It looks like you have not created any classes/sessions/terms or
                activated a new term. Click{' '}
                <Link href="/admin/manager">here</Link> to quickly run this
                setup.
                <br />
                Contact us if you are having any difficulties.
              </Typography>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

const mapDispatchToProps = dispatch => ({
  getCurrentSessionTerm: () => dispatch(getCurrentSessionTerm()),
  getClasses: () => dispatch(getTermClasses()),
  createClass: body => dispatch(createTermClass(body)),
  deleteClass: id =>
    dispatch(
      commonActions.showConfirmation({
        title: 'Delete class',
        message: 'delete class',
        open: true,
        handler: () => deleteTermClass(id)(dispatch),
      })
    ),
  toggleDialog: (key, open) =>
    dispatch(actions.updateField({ key, value: open })),
})

const mapStateToProps = state => ({
  ...state.classes,
  current: state.profile.session_term,
})
export default connect(mapStateToProps, mapDispatchToProps)(TermClasses);

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  school: {},
  result: {},
  check_result: {
    pin: '',
    student_id: '',
  },
  is_pin_valid: false,
  fee_form: {
    student_id: '',
    fee_id: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    invalid_student: false,
  },
  fee_response: {
    fees: [],
    student: null,
  },
  payment: {},
};

export const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    updateField: (state, { payload }) => {
      state[payload.key] = payload.value;
    },

    updateCheckResult: (state, { payload }) => {
      if (!payload) {
        state.check_result = initialState.check_result;
      } else if (payload.key) {
        state.check_result = {
          ...state.check_result,
          [payload.key]: payload.value,
        };
      } else {
        state.check_result = { ...state.check_result, ...payload };
      }
    },

    updateResult: (state, { payload }) => {
      if (!payload) {
        state.result = initialState.result;
      } else if (payload.key) {
        state.result = {
          ...state.result,
          [payload.key]: payload.value,
        };
      } else {
        state.result = { ...state.result, ...payload };
      }
    },

    updateFeeForm: (state, { payload }) => {
      if (!payload) {
        state.fee_form = initialState.fee_form;
      } else if (payload.key) {
        state.fee_form = {
          ...state.fee_form,
          [payload.key]: payload.value,
        };
      } else {
        state.fee_form = { ...state.fee_form, ...payload };
      }
    },
  },
});

export const actions = schoolSlice.actions;

export default schoolSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form: {
    name: '',
    email: '',
    phone: '',
    password: '',
  },
  loading: false,
  stats: {
    students: 0,
    teachers: 0,
    results: 0,
    subjects: 0,
    classes: 0,
  },
}
export const authSlice = createSlice({
         name: 'auth',
         initialState,
         reducers: {
           updateField: (state, { payload }) => {
             state[payload.key] = payload.value
           },
           updateForm: (state, action) => {
             state.form = {
               ...state.form,
               [action.field]: action.value,
             }
           },
         },
       })

// Action creators are generated for each case reducer function
export const actions = authSlice.actions

export default authSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  form: {
    name: '',
  },
  student: null,
  students: [],
  total: [],
  current_student_class: null,
  scores: null,
  loading: false,
  open_create_dialog: false,
  open_edit_dialog: false,
  open_csv_dialog: false,
  result: null,
  pagination: {
    page: 1,
    query: '',
    perPage: 10,
  },
  edit_dialog: {},
  allStudents: {
    scoresDialog: false,
    scores: [],
    validated: false,
    errors: null,
  },
};

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    updateField: (state, { payload }) => {
      state[payload.key] = payload.value;
    },

    updateForm: (state, action) => {
      state.form = {
        ...state.form,
        [action.field]: action.value,
      };
    },

    updatePagination: (state, { payload }) => {
      if (!payload || payload?.reset) {
        state.pagination = initialState.pagination;
      }
      if (typeof payload === 'object')
        state.pagination = {
          ...state.pagination,
          ...payload,
        };
    },
    updateEditDialog: (state, { payload }) => {
      if (!payload || payload?.reset) {
        state.pagination = initialState.pagination;
      }
      if (typeof payload === 'object')
        state.pagination = {
          ...state.pagination,
          ...payload,
        };
    },
    updateAllStudentsDialog: (state, { payload }) => {
      if (!payload || payload?.reset) {
        state.allStudents = initialState.allStudents;
      }
      if (typeof payload === 'object')
        state.allStudents = {
          ...state.allStudents,
          ...payload,
        };
    },
    updateStudents: (state, { payload }) => {
      state.students = payload.students;
      state.total = payload.total;
    },
  },
});

export const actions = studentSlice.actions;

export default studentSlice.reducer;

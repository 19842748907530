import React from 'react'
import axios from 'axios'
import { BACKEND } from 'consts'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// @material-ui/icons
import PinDrop from '@material-ui/icons/PinDrop'
import Phone from '@material-ui/icons/Phone'
import Check from '@material-ui/icons/Check'
// core components
import GridContainer from 'home-components/Grid/GridContainer.js'
import GridItem from 'home-components/Grid/GridItem.js'
import InfoArea from 'home-components/InfoArea/InfoArea.js'
import Card from 'home-components/Card/Card.js'
import CardHeader from 'home-components/Card/CardHeader.js'
import CardBody from 'home-components/Card/CardBody.js'
import CardFooter from 'home-components/Card/CardFooter.js'
import CustomInput from 'home-components/CustomInput/CustomInput.js'
import Button from 'home-components/CustomButtons/Button.js'

import contactsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js'

import city from 'assets/img/office1.jpg'

const useStyles = makeStyles(contactsStyle)

export default function Contact({ ...rest }) {
  const [checked, setChecked] = React.useState([]),
    initial = {
      firstname: '',
      lastname: '',
      email: '',
      message: '',
    },
    [body, updateBody] = React.useState(initial),
    handleToggle = value => {
      const currentIndex = checked.indexOf(value)
      const newChecked = [...checked]
      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }
      setChecked(newChecked)
    },
    classes = useStyles(),
    { firstname, lastname, email, message } = body,
    onChange = ({ target: { name, value } }) =>
      updateBody({ ...body, [name]: value }),
    submit = () =>
      axios
        .post(`${BACKEND}/admin/contact`, body)
        .then(({ data }) => {
          alert(data.message)
          updateBody(initial)
        })
        .catch(() =>
          alert('Failed to send your message. Please try again later')
        )
  return (
    <div className="cd-section" {...rest}>
      {/* Contact us 1 START */}
      <div
        id="contact"
        className={classes.contacts + ' ' + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5}>
              <h2 className={classes.title}>Get in Touch</h2>
              <h5 className={classes.description}>
                You need more information? Kindly send us a message.
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="Find us at the office"
                description={
                  <span>
                    Elizabeth plaza opp Uselu market,
                    <br /> Benin City,
                    <br /> Nigeria.
                  </span>
                }
                icon={PinDrop}
              />
              <InfoArea
                className={classes.infoArea}
                title="Give us a ring"
                description={
                  <span>
                    +234 813 705 0262
                    <br />
                    +234 806 963 3514
                    <br /> Mon - Fri, 8:00 AM - 5:00 PM
                  </span>
                }
                icon={Phone}
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader
                    contact
                    color="primary"
                    className={classes.textCenter}
                  >
                    <h4 className={classes.cardTitle}>Contact Us</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="First Name"
                          id="first"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: 'firstname',
                            value: firstname,
                            onChange,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Last Name"
                          id="last"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: 'lastname',
                            value: lastname,
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <CustomInput
                      labelText="Email Address"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'email',
                        name: 'email',
                        value: email,
                        onChange,
                      }}
                    />
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        name: 'message',
                        value: message,
                        onChange,
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="I'm not a robot"
                    />
                    <Button
                      disabled={!email || !message || !checked.length}
                      color="primary"
                      className={classes.pullRight}
                      onClick={submit}
                    >
                      Send Message
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Contact us 1 END */}
    </div>
  )
}

export const studentsSample = [
  {
    firstname: 'first',
    middlename: 'student',
    lastname: 'csv',
    gender: 'male',
    dob: '2002-11-15',
    class: 'primary 1',
  },
  {
    firstname: 'adekunle',
    middlename: 'ciroma',
    lastname: 'chukwu',
    gender: 'female',
    dob: '2002-10-02',
    class: 'primary 1',
  },
  {
    firstname: 'ono',
    middlename: 'mide',
    lastname: 'saro',
    gender: 'female',
    dob: '2003-01-03',
    class: 'primary 2',
  },
  {
    firstname: 'femi',
    middlename: 'kala',
    lastname: 'edev',
    gender: 'female',
    dob: '2003-03-23',
    class: 'primary 2',
  },
];

export const teachersSample = [
  {
    firstname: 'john',
    lastname: 'doe',
    gender: 'male',
    dob: '1987-06-24',
    subject: 'mathematics',
  },
  {
    firstname: 'mary',
    lastname: 'smith',
    gender: 'female',
    dob: '1985-11-16',
  },
];

export const sampleClasses = ['primary 1', 'primary 2'];
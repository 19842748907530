import axios from 'axios';
import { actions } from './redux';
import { BACKEND } from 'consts';
import { actions as commonActions } from '../../common/redux';
import { getSubdomain } from 'helpers';
const { toggleErrorAlert, setLoading } = commonActions;

export const getSchool = () => async (dispatch, getState) => {
  try {
    const { router, school } = getState(),
      sub = router.location.pathname.split("/s/")[1],
      subdomain = sub.split("/")[0];

    if (school.school.name) return;

    let local_school = localStorage.getItem("school");

    if (local_school) local_school = JSON.parse(local_school);

    if (local_school?.subdomain === subdomain) {
      dispatch(actions.updateField({ key: "school", value: local_school }));
    }
    let value = (await axios.get(`${BACKEND}/school/get/${subdomain}`)).data
      .data;
    if (value) {
      localStorage.setItem("school", JSON.stringify(value));
      dispatch(actions.updateField({ key: "school", value }));
    }
  } catch (error) {
    dispatch(toggleErrorAlert(error.message));
  }
};

export const verifyPin = () => (dispatch, getState) => {
  const { school, check_result } = getState().school,
    { pin, student_id } = check_result;

  return axios
    .get(
      `${BACKEND}/school/${school?.subdomain}/verify/pin?pin=${pin}&student_id=${student_id}`,
    )
    .then(() =>
      dispatch(actions.updateField({ key: "is_pin_valid", value: true })),
    )
    .catch((error) => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message),
      );
    });
};

const getResultRequestParams = ({ school, check_result }) => {
  const { subdomain } = school;
  const { pin, student_id } = check_result;
  return { subdomain, pin, student_id };
};

export const getStudentResult = () => (dispatch, getState) => {
  dispatch(setLoading(true));

  const { subdomain, pin, student_id } = getResultRequestParams(
    getState().school,
  );

  if (!subdomain) {
    console.log("no subdomain found");
    return;
  }

  if (!pin || !student_id)
    return dispatch(actions.updateField({ key: "is_pin_valid", value: false }));

  return axios
    .get(
      `${BACKEND}/school/get/${subdomain}/result?pin=${pin}&student_id=${student_id}`,
    )
    .then(({ data }) => {
      dispatch(
        actions.updateField({
          key: "is_pin_valid",
          value: true,
        }),
      );
      dispatch(actions.updateResult(data.data));
      dispatch(getPosition());

      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(
        actions.updateField({
          key: "is_pin_valid",
          value: false,
        }),
      );
    });
};

export const getPosition = () => (dispatch, getState) => {
  const { subdomain, pin, student_id } = getResultRequestParams(
    getState().school,
  );

  return axios
    .get(
      `${BACKEND}/school/get/${subdomain}/position?pin=${pin}&student_id=${student_id}`,
    )
    .then(({ data }) => dispatch(actions.updateResult({ position: data.data })))
    .catch((error) => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message),
      );
    });
};

export const getStudentFees = (student_id) => (dispatch, getState) => {
  if (!student_id || String(student_id).length < 3) return;
  const { subdomain } = getResultRequestParams(getState().school);

  return axios
    .get(`${BACKEND}/school/${subdomain}/student/${student_id}`)
    .then(({ data }) => {
      dispatch(actions.updateFeeForm({ invalid_student: false }));
      data.data?.fees?.length &&
        dispatch(
          actions.updateField({ key: "fee_response", value: data.data }),
        );
    })
    .catch((error) => {
      if (error.response?.data.errors.message === "Error retrieving student") {
        dispatch(
          actions.updateField({
            key: "fee_response",
            value: { fees: [], student: null },
          }),
        );
        return dispatch(actions.updateFeeForm({ invalid_student: true }));
      }
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message),
      );
    });
};

export const getCurrentTermFees = () => (dispatch, getState) => {
  const school_name = getSubdomain(getState().router.location.pathname);

  if (!school_name) return;

  return axios
    .get(`${BACKEND}/school/get/${school_name}/fees`)
    .then(({ data }) => {
      dispatch(
        actions.updateField({ key: "current_term_fees", value: data.data }),
      );
    })
    .catch((error) => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message),
      );
    });
};

export const initiatePayment = () => (dispatch, getState) => {
  const { router, school } = getState(),
    school_name = getSubdomain(router.location.pathname),
    { student_id, fee_id, firstname, lastname, email, phone } = school.fee_form;

  return axios
    .post(`${BACKEND}/school/${school_name}/pay`, {
      student: student_id,
      fee_id,
      firstname,
      lastname,
      email,
      phone,
    })
    .then(({ data }) => {
      window.location = data.data?.paymentLink;
    })
    .catch((error) => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message),
      );
    });
};

export const verifyPayment =
  ({ status, ref, transaction_id }) =>
  (dispatch, getState) => {
    const { router } = getState(),
      school_name = getSubdomain(router.location.pathname);

    if (!status || !ref || !transaction_id) return;
    return axios
      .get(
        `${BACKEND}/school/${school_name}/pay/complete?&status=${status}&ref=${ref}&transaction_id=${transaction_id}`,
      )
      .then(({ data }) => {
        dispatch(actions.updateField({ key: "payment", value: data.data }));
      })
      .catch((error) => {
        dispatch(
          toggleErrorAlert(
            error.response?.data.errors.message || error.message,
          ),
        );
      });
  };

import axios from 'axios'
import { actions } from './redux'
import { BACKEND, BACKEND_API } from 'consts'
import { actions as commonActions } from 'common/redux'
const { showNotification, toggleErrorAlert } = commonActions

export const updateField = payload => dispatch =>
  dispatch(actions.updateField(payload))

export const updateForm = payload => dispatch =>
  dispatch(actions.updateForm(payload))

export const register = (data, reset) => dispatch => {
  return axios
    .post(`${BACKEND}/auth/register`, data)
    .then(() => {
      dispatch(showNotification({ message: 'Registration successful' }))
      reset()
      window.location = '/auth/login'
    })
    .catch(error => {
      reset()
      let errors = ``
      for (const e in error.response.data.errors) {
        errors += `${error.response.data.errors[e]}, `
      }
      dispatch(toggleErrorAlert(errors))
    })
}

export const login = (data, reset) => (dispatch, getState) => {
  return axios
    .post(`${BACKEND}/auth/login`, data)
    .then(({ data }) => {
      const token = data.data.token
      localStorage.setItem('token', token)
      localStorage.setItem('user', data.data.user)
      dispatch(showNotification({ message: 'Login successful' }))
      reset()
      const current_path = getState().router.location.query?.path
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      window.location = current_path || '/admin/dashboard'
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
      if (reset) reset()
    })
}

export const logout = () => dispatch => {
  axios.defaults.headers.common['Authorization'] = null
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('school')
  window.location = '/auth/login'
}

export const getStats = () => dispatch =>
  axios
    .get(`${BACKEND_API}/accounts/stats`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'stats', value: data.data }))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
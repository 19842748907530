import React from 'react'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import GridItem from 'home-components/Grid/GridItem.js'

import featuresStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js'

const useStyles = makeStyles(featuresStyle)

export default function About({ ...rest }) {
  const classes = useStyles()
  return (
    <div id="about" className="cd-section" {...rest}>
      <div className={classNames(classes.section)}>
        <div className={classes.features}>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + ' ' + classes.mrAuto}
          >
            <h2 className={classes.title}>About Sage</h2>
            <h5 className={classes.description}>
              Sage is an all in one solution for educational institutions. It
              was developed to accelerate the transition of school management to
              the cloud. An easy to use system built to help schools to not just
              increase, but also manage revenues more effectively. Sage is free
              and can be setup in less than 10 minutes.
            </h5>
          </GridItem>
        </div>
      </div>
    </div>
  )
}

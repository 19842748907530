import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  confirm_dialog: {
    title: "",
    message: "",
    open: false,
    handler: null,
  },
  notification: {
    message: "",
    open: false,
    type: "success",
  },
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateField: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    toggleErrorAlert: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    showConfirmation: (state, { payload }) => {
      state.confirm_dialog = { ...payload, open: true };
    },
    cancelConfirmation: state => {
      state.confirm_dialog = initialState.confirm_dialog;
    },
    showNotification: (state, { payload }) => {
      state.notification = { ...state.notification, ...payload, open: true };
    },
    closeNotification: state => {
      state.notification = initialState.notification;
    },
  },
});

export const actions = commonSlice.actions;

export default commonSlice.reducer;

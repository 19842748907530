import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from 'components/Table/Table';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormDialog from '../../../common/components/FormDialog';
import MenuButton from '../../../common/components/MenuButton';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';

import {
  getClassFees,
  editClassFee,
  createClassFee,
  deleteClassFee,
  createClassFees,
} from '../actions';
import { actions } from '../redux';
import { showConfirmation } from 'common/actions';
import { getCurrentTermClasses } from '../../../views/Classes/actions';

const useStyles = makeStyles(styles);

function ClassFeeTypes({
  getClassFees,
  class_fees,
  current_term_classes,
  fee_types,
  open_create_dialog,
  open_edit_dialog,
  open_csv_dialog,
  toggleDialog,
  createClassFee,
  editClassFee,
  deleteClassFee,
  createClassFees,
  getCurrentTermClasses,
}) {
  const [checked, setChecked] = React.useState([]),
    handleToggle = value => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    },
    fees = useStyles(),
    [id, updateId] = React.useState(null),
    [amount, updateAmount] = React.useState(''),
    handleEdit = (id, amount) => {
      updateId(id);
      updateAmount(amount);
      toggleDialog('open_edit_dialog', true);
    },
    handleDelete = id => deleteClassFee(id),
    fillButtons = (id, amount) =>
      [
        { color: 'info', icon: Edit, func: () => handleEdit(id, amount) },
        { color: 'danger', icon: Close, func: () => handleDelete(id) },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={fees.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={fees.icon} />
          </Button>
        );
      });
  React.useEffect(() => {
    getClassFees();
  }, [getClassFees]);
  return (
    <GridContainer>
      {open_create_dialog && (
        <FormDialog
          title="Create class fee"
          formFields={{ amount: '' }}
          selectFields={{
            class: current_term_classes,
            fee: fee_types,
          }}
          handleClose={() => toggleDialog('open_create_dialog', false)}
          submit={create => createClassFee(create)}
        />
      )}
      {open_edit_dialog && (
        <FormDialog
          title="Edit class fee"
          formFields={{ amount }}
          handleClose={() => toggleDialog('open_edit_dialog', false)}
          submit={update => editClassFee({ id, ...update })}
        />
      )}
      {open_csv_dialog && (
        <FormDialog
          title="Create class fees from csv file"
          csvFields={['amount']}
          csvSampleLink="https://sample-fil.s3.eu-west-3.amazonaws.com/sample_class_fees.csv"
          handleClose={() => toggleDialog('open_csv_dialog', false)}
          submit={body => createClassFees(body)}
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <div className={fees.cardContentRight}>
                  <MenuButton
                    title="Create"
                    class_name="floatRight"
                    buttons={[
                      {
                        name: 'Create One',
                        handler: () => {
                          getCurrentTermClasses();
                          toggleDialog('open_create_dialog', true);
                        },
                      },
                      {
                        name: 'Upload CSV',
                        handler: () => toggleDialog('open_csv_dialog', true),
                      },
                    ]}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            {!!class_fees?.length ? (
              <Table
                tableHead={['', 'Class', 'Type', 'Amount', '']}
                tableData={class_fees?.map(f => {
                  const { amount, id } = f;
                  return [
                    <Checkbox
                      key="key"
                      className={fees.positionAbsolute}
                      tabIndex={-1}
                      onClick={() => handleToggle(id)}
                      checkedIcon={<Check className={fees.checkedIcon} />}
                      icon={<Check className={fees.uncheckedIcon} />}
                      classes={{
                        checked: fees.checked,
                        root: fees.checkRoot,
                      }}
                    />,
                    f['session_term_class.class.name'],
                    f['fee_type.name'],
                    amount,
                    fillButtons(id, amount),
                  ];
                })}
                customCellClasses={[fees.center, fees.right, fees.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[fees.center, fees.right, fees.right]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            ) : (
              <Typography variant="body2">
                Click on the create button to create school fees
                <br />
                Contact us if you are having any difficulties.
              </Typography>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapDispatchToProps = dispatch => ({
  getClassFees: () => dispatch(getClassFees()),
  getCurrentTermClasses: () => dispatch(getCurrentTermClasses()),
  createClassFee: body => dispatch(createClassFee(body)),
  createClassFees: body => dispatch(createClassFees(body)),
  editClassFee: body => dispatch(editClassFee(body)),
  deleteClassFee: id =>
    dispatch(
      showConfirmation('Delete fee', 'delete fee', () =>
        deleteClassFee(id)(dispatch)
      )
    ),
  toggleDialog: (key, open) =>
    dispatch(actions.updateField({ key, value: open })),
});

const mapStateToProps = state => ({
  ...state.fees,
  current_term_classes: state.classes.current_term_classes,
});
export default connect(mapStateToProps, mapDispatchToProps)(ClassFeeTypes);

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  form: {
    name: '',
  },
  fee_types: [],
  class_fees: [],
  loading: false,
  open_create_dialog: false,
  open_edit_dialog: false,
  open_csv_dialog: false,
  open_create_type_dialog: false,
  open_edit_type_dialog: false,
  open_csv_type_dialog: false,
}
export const feeSlice = createSlice({
    name: 'fee',
    initialState,
    reducers: {
      updateField: (state, { payload }) => {
        state[payload.key] = payload.value
      },
      updateForm: (state, action) => {
        state.form = {
          ...state.form,
          [action.field]: action.value,
        }
      },
    },
  }),
  actions = feeSlice.actions

export default feeSlice.reducer

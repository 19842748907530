import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import CResult from "views/Components/CResult";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

import { getScores, getResultInfo } from "../actions";
import { getSchoolProfile } from "../../Profile/actions";
import { getTermClass } from "../../Classes/actions";

const useStyles = makeStyles(styles);

function ManageResults({
  hide_print,
  scores,
  getScores,
  getResultInfo,
  result_info,
  stats,
  getSchoolProfile,
  school,
  getTermClass,
  term_class,
}) {
  const classes = useStyles();

  const { id: student_id, class_id } = useParams();

  const ResultInfo = useCallback(
    (props) => (
      <CResult
        class_id={class_id}
        scores={scores}
        result_info={result_info}
        stats={stats}
        school={school}
        term_class={term_class}
        student_id={student_id}
        {...props}
      />
    ),
    [class_id, scores, result_info, stats, school, term_class, student_id],
  );
  useEffect(() => {
    getSchoolProfile();
    if (!student_id) return;
    getScores(class_id, student_id);
    getResultInfo(class_id, student_id);
    getTermClass(class_id);
  }, [
    getScores,
    student_id,
    class_id,
    getResultInfo,
    getSchoolProfile,
    getTermClass,
  ]);

  return (
    <div>
      {!hide_print && (
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.cardContentRight}>
                    <PDFDownloadLink
                      key="pdf"
                      document={<ResultInfo pdfMode={true} />}
                      //  fileName={`${firstname}-${lastname} result.pdf`}
                      fileName={`result.pdf`}
                      aria-label="Save PDF"
                      title="Save PDF"
                      className="download-pdf"
                    ></PDFDownloadLink>
                    <p style={{ cursor: "pointer" }}>Save PDF</p>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      )}
      <ResultInfo />
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  getScores: (class_id, student_id) =>
    dispatch(getScores(class_id, student_id)),
  getResultInfo: (class_id, student_id) =>
    dispatch(getResultInfo(class_id, student_id)),
  getSchoolProfile: () => dispatch(getSchoolProfile(true)),
  getTermClass: id => dispatch(getTermClass(id)),
});

const mapStateToProps = state => ({
  ...state.results,
  school: state.profile.school,
  term_class: state.classes.term_class,
  ...state.school.result,
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageResults);

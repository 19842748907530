/*eslint-disable*/
import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import Header from 'home-components/Header/Header.js'
import GridContainer from 'home-components/Grid/GridContainer.js'
import GridItem from 'home-components/Grid/GridItem.js'

import errorPageStyle from 'assets/jss/material-kit-pro-react/views/errorPageStyles.js'

import image from 'assets/img/clint-mckoy.jpg'
import HeaderLinks from 'views/HomePage/Sections/HeaderLinks'
import HomeNavbar from 'components/Navbars/HomeNavbar'

const useStyles = makeStyles(errorPageStyle)

export default function ErrorPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  })
  const classes = useStyles()
  return (
    <div>
      <HomeNavbar />
      {/* <Header
        absolute
        color="transparent"
        brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="dark" />}
        {...rest}
      /> */}
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        {/* <div className={classes.container}> */}
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h1 className={classes.title}>404</h1>
              <h2 className={classes.subTitle}>Page not found :(</h2>
              <h4 className={classes.description}>
                Ooooups! Looks like you got lost.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
        {/* </div> */}
      </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// core components
import Card from 'home-components/Card/Card.js';
import CardHeader from 'home-components/Card/CardHeader.js';
import CardBody from 'home-components/Card/CardBody.js';
import CardFooter from 'home-components/Card/CardFooter.js';
import CustomInput from 'home-components/CustomInput/CustomInput.js';
import Button from 'home-components/CustomButtons/Button.js';

import contactsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js';

import { actions } from '../redux';
import { getStudentFees, initiatePayment } from '../actions';
import { capitalizeFirst } from 'helpers';
import { showConfirmation } from 'common/actions';

const useStyles = makeStyles(contactsStyle);

function FeeSection({
  fee_form,
  fee_response,
  updateFeeForm,
  getStudentFees,
  confirmPayment,
}) {
  const classes = useStyles(),
    { student, fees } = fee_response,
    {
      student_id,
      fee_id,
      firstname,
      lastname,
      email,
      phone,
      invalid_student,
    } = fee_form,
    onChange = ({ target: { name, value } }) => {
      const update = { [name]: value };
      if (name === 'student_id') update.fee_id = '';
      updateFeeForm(update);
    },
    amount = fee_id && fees?.find(({ id }) => id === fee_id)?.amount;

  useEffect(() => {
    if (student_id) getStudentFees(student_id);
  }, [student_id, getStudentFees]);

  useEffect(() => {
    if (fees?.length === 1) {
      const { id } = fees[0];
      updateFeeForm({ fee_id: id });
    }
  }, [fees, updateFeeForm]);

  return (
    <Card className={classes.card1}>
      <form>
        <CardHeader contact color="primary" className={classes.textCenter}>
          <h4 className={classes.cardTitle}>Pay fees</h4>
        </CardHeader>
        <CardBody>
          <CustomInput
            labelText="Student ID"
            id="student_id"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'student_id',
              value: student_id,
              onChange,
            }}
          />

          {invalid_student && (
            <small className={classes.small}>Student record not found</small>
          )}
          {student?.firstname && student?.lastname && (
            <small className={classes.small}>
              {student.firstname} {student.middlename} {student.lastname}
            </small>
          )}
          {fees?.length > 1 && (
            <Grid container item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id={`fee-select-label`}>Select fee</InputLabel>
                <Select
                  labelId={`fee-select-label`}
                  id={`fee-select`}
                  value={fee_id}
                  onChange={onChange}
                  name="fee_id"
                >
                  {fees?.map(f => (
                    <MenuItem value={f.id} key={f.id}>
                      {capitalizeFirst(f['fee_type.name'])}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <CustomInput
            labelText="First name"
            id="firstname"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'firstname',
              value: firstname,
              onChange,
            }}
          />
          <CustomInput
            labelText="Last name"
            id="lastname"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'lastname',
              value: lastname,
              onChange,
            }}
          />
          <CustomInput
            labelText="Email"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'email',
              value: email,
              onChange,
            }}
          />
          <CustomInput
            labelText="Phone"
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'phone',
              value: phone,
              onChange,
            }}
          />
        </CardBody>
        <CardFooter className={classes.justifyContentBetween}>
          <Button
            disabled={
              !student_id ||
              !fee_id ||
              !firstname ||
              !lastname ||
              !email ||
              !phone
            }
            color="primary"
            className={classes.pullRight}
            onClick={() =>
              confirmPayment(
                `${student.firstname} ${student.middlename} ${student.lastname}`,
                amount
              )
            }
          >
            Confirm
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
}
const mapStateToProps = state => state.school;

const mapDispatchToProps = (dispatch, getState) => ({
  updateFeeForm: body => dispatch(actions.updateFeeForm(body)),
  getStudentFees: student_id => dispatch(getStudentFees(student_id)),
  confirmPayment: (name, amount) =>
    dispatch(
      showConfirmation(
        'payment',
        `proceed with the payment of ₦${amount} for ${name}`,
        initiatePayment
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeeSection);

import React from "react";
import { connect } from 'react-redux'
// react plugin for creating charts

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'

// @material-ui/icons
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import ClassIcon from '@material-ui/icons/Class'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'

import { getStats } from 'views/Auth/actions'

const useStyles = makeStyles(styles)

function Dashboard({ stats, getStats }) {
  const classes = useStyles()
  React.useEffect(() => {
    getStats()
  }, [getStats])
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <i className="fas fa-graduation-cap" />
              </CardIcon>
              <p className={classes.cardCategory}>Students</p>
              <h3 className={classes.cardTitle}>{stats.students}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <SupervisedUserCircleIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Teachers</p>
              <h3 className={classes.cardTitle}>{stats.teachers}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Subjects</p>
              <h3 className={classes.cardTitle}>{stats.subjects}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <ClassIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Classes</p>
              <h3 className={classes.cardTitle}>{stats.classes}</h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const mapStateToProps = state => ({
  stats: state.auth.stats,
})

const mapDispatchToProps = dispatch => ({
  getStats: () => dispatch(getStats()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

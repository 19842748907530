// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ClassIcon from '@material-ui/icons/Class';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';

import Dashboard from './views/Dashboard/Dashboard';

import LoginPage from './views/Auth/screens/LoginPage';
import RegisterPage from './views/Auth/screens/RegisterPage';
import ProfilePage from './views/Profile/screens/Manage';
import ClassPage from './views/Classes/screens/TermClasses';
import StudentsPage from './views/Classes/screens/TermClassStudents';
import StudentDetails from './views/Students/screens/Details';
import StudentScores from './views/Students/screens/Scores';
import ResultPage from './views/Result/screens/Manage';
import Manager from './views/Manager/screens/Manage';
import TeachersPage from './views/Teachers/screens/Manage';
import HomePage from './views/HomePage/HomePage';
import ErrorPage from './views/ErrorPage/ErrorPage';
import SchoolPage from './views/School/SchoolPage';
import StudentResult from './views/School/Screens/StudentResult';
import Fees from './views/Fees';
import AllStudents from './views/Students/screens/AllStudents';

const dashRoutes = [
  {
    path: "/",
    name: "Homepage",
    mini: "L",
    View: HomePage,
    layout: "/home",
    redirect: true,
  },
  {
    path: "/home/404",
    name: "Not found",
    mini: "L",
    View: ErrorPage,
    layout: "/home",
    redirect: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    View: Dashboard,
    layout: "/admin",
    setup: true,
  },
  {
    path: "/classes",
    name: "Classes",
    icon: ClassIcon,
    View: ClassPage,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: AccountBalanceIcon,
    View: ProfilePage,
    layout: "/admin",
    setup: true,
  },
  {
    path: "/teachers",
    name: "Teachers",
    icon: SupervisorAccountIcon,
    View: TeachersPage,
    layout: "/admin",
  },
  {
    path: "/students",
    name: "Students",
    icon: PersonIcon,
    View: AllStudents,
    layout: "/admin",
  },
  {
    path: "/class/:id",
    name: "Students",
    View: StudentsPage,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/class/student/:id",
    name: "Student",
    View: StudentDetails,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/students/scores/:class_id/:id",
    name: "Student's scores",
    View: StudentScores,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/manager",
    name: "Manage school",
    icon: SettingsIcon,
    View: Manager,
    layout: "/admin",
    setup: true,
  },
  {
    path: "/fees",
    name: "Manage fees",
    icon: PaymentIcon,
    View: Fees,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login Page",
    mini: "L",
    View: LoginPage,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/register",
    name: "Register Page",
    mini: "R",
    View: RegisterPage,
    layout: "/auth",
    icon: AccountBalanceIcon,
    redirect: true,
  },
  {
    path: "/results/:class_id/:id",
    name: "Result",
    mini: "R",
    View: ResultPage,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/:name/home",
    name: "School",
    mini: "S",
    View: SchoolPage,
    layout: "/s",
    redirect: true,
  },
  {
    path: "/:name/result",
    name: "Result",
    mini: "R",
    View: StudentResult,
    layout: "/s",
    redirect: true,
  },
  {
    path: "/:name/pay/:status",
    name: "School",
    mini: "S",
    View: SchoolPage,
    layout: "/s",
    redirect: true,
  },
  {
    path: "/home",
    name: "Website",
    mini: "H",
    layout: "/admin",
    icon: HomeIcon,
    home: true,
  },
  // {
  //   path: '/new',
  //   name: 'Result',
  //   mini: 'H',
  //   layout: '/admin',
  //   icon: HomeIcon,
  //   View: InvoicePage,
  //   home: true,
  // },
];
export default dashRoutes;

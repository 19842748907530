import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

// material-ui icons
import Close from '@material-ui/icons/Close';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormDialog from '../../../common/components/FormDialog';
import MenuButton from '../../../common/components/MenuButton';
import StudentsTable from '../../../views/Classes/components/StudentsTable';
import FilterButton from '../../../common/components/FilterButton';
import LocalFileUpload from '../../../common/components/LocalFileUpload';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';

import { getAllStudents, updateFiles, updateStudent } from '../actions';
import { capitalizeFirst } from 'helpers';
import { actions } from '../redux';
import { actions as classAction } from '../../Classes/redux';
import {
  createTermClassStudent,
  createTermClassStudents,
  getTermClasses,
} from '../../../views/Classes/actions';
import AllStudentsScoresDialog from '../components/AllStudentsScoresDialog';
import { studentsSample } from 'utils/samples';

const useStyles = makeStyles(styles);

function AllStudents({
  getAllStudents,
  students,
  total,
  addStudent,
  deleteStudent,
  updateField,
  updateStudent,
  addStudents,
  pagination,
  updatePagination,
  getTermClasses,
  term_classes,
  open_create_dialog,
  edit_dialog,
  open_csv_dialog,
  updateFilter,
  filters,
  updateFiles,
}) {
  const classes = useStyles(),
    dispatch = useDispatch(),
    fillButtons = ({ student }) => {
      return [
        // { color: 'info', icon: Edit, func: () => updateField('open_edit_dialog', student) },
        {
          color: 'danger',
          icon: Close,
          func: () => deleteStudent(student?.id),
        },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
    },
    { id, title, student_id, ...edit_data } = edit_dialog;

  useEffect(() => {
    getAllStudents();
  }, [getAllStudents, pagination.query, pagination.page, filters.class_id]);
  useEffect(() => {
    getTermClasses();
  }, [getTermClasses]);

  return (
    <GridContainer>
      <AllStudentsScoresDialog />
      {open_create_dialog && (
        <FormDialog
          fullWidthDate
          title="Add student"
          formFields={{
            firstname: '',
            middlename: '',
            lastname: '',
          }}
          dateFields={{ dob: new Date() }}
          selectFields={{
            gender: [
              { label: 'Male', value: 'male' },
              { label: 'Female', value: 'female' },
            ],
            class: term_classes,
          }}
          handleClose={() => updateField('open_create_dialog', false)}
          submit={body =>
            addStudent(body, () => {
              getAllStudents();
              updateField('open_create_dialog', false);
            })
          }
        />
      )}
      {id && (
        <FormDialog
          title="Edit student"
          formFields={edit_data}
          handleClose={() => updateField('open_edit_dialog', false)}
          selectFields={{
            title: ['mr', 'mrs', 'miss'].map(value => ({
              label: capitalizeFirst(value),
              value,
            })),
          }}
          selectValues={{ title }}
          submit={update =>
            updateStudent({
              id,
              ...update,
              reset: () => updateField('open_edit_dialog', false),
            })
          }
        />
      )}
      {open_csv_dialog && (
        <FormDialog
          title="Create students from csv file"
          csvFields={[
            'firstname',
            'middlename',
            'lastname',
            'gender',
            'dob',
            'class',
          ]}
          filename="students"
          csvSampleDatabase={studentsSample}
          handleClose={() => updateField('open_csv_dialog', false)}
          submit={body =>
            addStudents({
              body,
              reset: () => updateField('open_csv_dialog', false),
            })
          }
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem
                xs={12}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <TextField
                  fullWidth
                  type="text"
                  margin="dense"
                  name={'query'}
                  label="Search..."
                  value={pagination.query}
                  className={classes.search}
                  onChange={({ target }) =>
                    updatePagination({ query: target.value })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!pagination.query}
                          onClick={() => updatePagination({ query: '' })}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FilterButton
                  title="Filter"
                  value={filters.class_id}
                  actions={term_classes?.map(c => ({
                    name: c['class.name'],
                    value: c['class.id'],
                    handler: () => updateFilter({ class_id: c['class.id'] }),
                  }))}
                  handleClear={() => updateFilter()}
                />
                <MenuButton
                  title="More"
                  class_name="floatRight"
                  buttons={[
                    {
                      name: 'Add student',
                      handler: () => {
                        updateField('open_create_dialog', true);
                      },
                    },
                    {
                      name: 'Upload students',
                      handler: () => updateField('open_csv_dialog', true),
                    },
                    {
                      Component: () => (
                        <LocalFileUpload
                          multiple
                          name="headshots-upload-team"
                          onChange={updateFiles}
                          Component={() => 'Upload passports'}
                        />
                      ),
                    },
                    {
                      name: 'Upload results',
                      handler: () =>
                        dispatch(
                          actions.updateAllStudentsDialog({
                            scoresDialog: true,
                          })
                        ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <StudentsTable
        students={students}
        total={total}
        fillButtons={fillButtons}
        pagination={pagination}
        handlePagination={page => updatePagination({ page })}
      />
    </GridContainer>
  );
}

const mapDispatchToProps = dispatch => ({
  getAllStudents: () => dispatch(getAllStudents()),
  addStudent: (body, cb) => dispatch(createTermClassStudent(body, cb)),
  deleteStudent: body => dispatch(console.log(body)),
  updateField: (key, value) => dispatch(actions.updateField({ key, value })),
  updateStudent: body => dispatch(updateStudent(body)),
  addStudents: body => dispatch(createTermClassStudents(body)),
  updatePagination: data => dispatch(actions.updatePagination(data)),
  getTermClasses: () => dispatch(getTermClasses()),
  updateFiles: files => dispatch(updateFiles(files)),
  updateFilter: data => dispatch(classAction.updateFilter(data)),
});

const mapStateToProps = state => ({
  ...state.students,
  term_classes: state.classes.term_classes,
  filters: state.classes.filters,
});
export default connect(mapStateToProps, mapDispatchToProps)(AllStudents);

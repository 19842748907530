import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form: {
    name: "",
  },
  subject: null,
  subjects: null,
  loading: false,
  open_create_dialog: false,
  open_edit_dialog: false,
  open_csv_dialog: false,
};
export const subjectSlice = createSlice({
    name: "subject",
    initialState,
    reducers: {
      updateField: (state, { payload }) => {
        state[payload.key] = payload.value;
      },
      updateForm: (state, action) => {
        state.form = {
          ...state.form,
          [action.field]: action.value,
        };
      },
    },
  }),
  actions = subjectSlice.actions;

export default subjectSlice.reducer;

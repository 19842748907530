import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import MenuButton from '../../common/components/MenuButton';
import Button from '../../components/CustomButtons/Button';

const useStyles = makeStyles(() => ({
  endIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function FilterButton({
  title,
  value,
  actions,
  handleClear,
  color = 'rose',
}) {
  const classes = useStyles();
  const selected = value && actions.find(a => a.value === value);
  if (selected?.name)
    return (
      <Button
        color={color}
        muiClasses={{ endIcon: classes.endIcon }}
        onClick={handleClear}
        endIcon={<Icon>clear</Icon>}
      >
        {selected?.name}
      </Button>
    );
  return (
    <MenuButton
      color="transparent"
      title={title}
      class_name="floatRight"
      buttons={actions}
    />
  );
}

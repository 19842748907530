import axios from 'axios';
import { actions } from './redux';
import { BACKEND_API } from 'consts';
import { checkRequiredFields, clean } from '../../helpers';
import {
  CSVFormatError,
  successfulCreateRequest,
  successfulUpdateRequest,
  successfulDeleteRequest,
} from '../../messages';
import { getResultInfo } from '../Result/actions';
import { actions as commonActions } from '../../common/redux';
const {
  showNotification,
  toggleErrorAlert,
  setLoading,
  cancelConfirmation,
} = commonActions;

export const getStudents = () => dispatch => {
  dispatch(setLoading(true));
  return axios
    .get(`${BACKEND_API}/student`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'student', value: data.data }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getStudent = id => dispatch => {
  dispatch(setLoading(true));
  dispatch(
    actions.updateField({
      key: 'student_id',
      value: id,
    })
  );
  return axios
    .get(`${BACKEND_API}/students/${id}`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'student', value: data.data }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getStudentCurrentClass = id => dispatch => {
  axios
    .get(`${BACKEND_API}/students/${id}/current-class`)
    .then(({ data }) => {
      dispatch(
        actions.updateField({ key: 'current_student_class', value: data.data })
      );
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const createStudent = body => dispatch => {
  dispatch(setLoading(true));
  return axios
    .post(`${BACKEND_API}/student`, body)
    .then(() => {
      dispatch(getStudents());
      dispatch(
        showNotification({ message: successfulCreateRequest('Student') })
      );
      dispatch(
        actions.updateField({ key: 'open_create_dialog', value: false })
      );
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const updateStudent = body => dispatch => {
  dispatch(setLoading(true));
  const { id, reset, ...rest } = body;
  return axios
    .put(`${BACKEND_API}/students/${id}`, rest)
    .then(() => {
      dispatch(getStudent(id));
      dispatch(
        showNotification({ message: successfulUpdateRequest('Student') })
      );
      reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const deleteStudent = id => dispatch => {
  dispatch(setLoading(true));
  return axios
    .delete(`${BACKEND_API}/students/${id}`)
    .then(() => {
      dispatch(getStudents());
      dispatch(
        showNotification({ message: successfulDeleteRequest('Student') })
      );
      dispatch(cancelConfirmation());
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const createStudents = student => dispatch => {
  dispatch(setLoading(true));
  const isInvalid = student.some(item => checkRequiredFields(item, ['name']));
  if (isInvalid) return dispatch(toggleErrorAlert(CSVFormatError()));
  return axios
    .post(`${BACKEND_API}/students/bulk`, { student })
    .then(() => {
      dispatch(getStudents());
      dispatch(
        showNotification({ message: successfulCreateRequest('Students') })
      );
      dispatch(actions.updateField({ key: 'open_csv_dialog', value: false }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const promoteStudent = ({ id, classes, reset }) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/students/${id}/promote`, { class_id: classes })
    .then(() => {
      dispatch(getStudentCurrentClass(id));
      dispatch(showNotification({ message: 'Student promoted successfully' }));
      reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getScores = (class_id, student_id) => dispatch =>
  axios
    .get(`${BACKEND_API}/scores/${class_id}/${student_id}`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'scores', value: data.data }));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });

export const createScore = ({
  class_id,
  student_id,
  subject,
  reset,
  ...body
}) => dispatch => {
  const payload = clean(body);
  if (!Object.keys(payload).length)
    return dispatch(toggleErrorAlert('Please fill at least one field'));
  if (subject) {
    body.subject_id = subject;
  } else {
    return dispatch(toggleErrorAlert('Subject must be selected'));
  }
  dispatch(setLoading(true));
  return axios
    .post(`${BACKEND_API}/scores/${class_id}/${student_id}`, payload)
    .then(() => {
      dispatch(getScores(class_id, student_id));
      dispatch(showNotification({ message: successfulCreateRequest('Score') }));
      reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const editScore = ({
  class_id,
  student_id,
  id,
  reset,
  ...body
}) => dispatch => {
  const payload = clean(body);
  if (!Object.keys(payload).length)
    return dispatch(toggleErrorAlert('Please fill at least one field'));
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/scores/${id}`, payload)
    .then(() => {
      dispatch(getScores(class_id, student_id));
      dispatch(showNotification({ message: successfulUpdateRequest('Score') }));
      reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const deleteScore = (class_id, student_id, id) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .delete(`${BACKEND_API}/scores/${id}`)
    .then(() => {
      dispatch(getScores(class_id, student_id));
      dispatch(showNotification({ message: successfulDeleteRequest('Score') }));
      dispatch(cancelConfirmation());
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const createScores = ({
  class_id,
  student_id,
  reset,
  scores,
}) => dispatch => {
  const format_scores = scores.map(score => {
    const parsed = {};
    Object.keys(score).map(field =>
      field === 'grade'
        ? (parsed[field] = score[field])
        : (parsed[field] = Number(score[field]))
    );
    return parsed;
  });

  dispatch(setLoading(true));
  return axios
    .post(`${BACKEND_API}/scores/bulk/${class_id}/${student_id}`, {
      scores: format_scores,
    })
    .then(() => {
      dispatch(getScores(class_id, student_id));
      reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const createAllStudentsScores = () => async (dispatch, getState) => {
  let update = { errors: null };
  try {
    const { scores, validated } = getState().students.allStudents,
      url = `${BACKEND_API}/scores${validated ? '' : '/validate'}/bulk`,
      payload = {
        scores,
      };
    dispatch(setLoading(true));
    await axios.post(url, payload);
    if (validated) update = null;
    else update.validated = true;
  } catch (error) {
    const errors = error.response?.data?.errors;
    if (Object.keys(errors).length > 0) update.errors = errors;
    else
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
  }
  dispatch(setLoading(false));
  dispatch(actions.updateAllStudentsDialog(update));
};

export const updateFile = ({ id, reset, body }) => dispatch => {
  dispatch(setLoading(true));
  const field = Object.keys(body)[0],
    data = new FormData();
  data.append(field, body[field]);
  return axios
    .put(`${BACKEND_API}/students/${id}/${field}`, data)
    .then(() => {
      dispatch(showNotification({ message: 'Update successful' }));
      dispatch(getStudent(id));
      reset && reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const updateComment = ({
  class_id,
  student_id,
  reset,
  ...body
}) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/scores/${class_id}/${student_id}/details`, body)
    .then(() => {
      dispatch(getResultInfo(class_id, student_id));
      reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getAllStudents = () => async (dispatch, getState) => {
  try {
    const { students, classes } = getState();
    const { page, perPage, query } = students.pagination;
    !query && dispatch(setLoading(true));
    let url = `${BACKEND_API}/students?page=${page}&perPage=${perPage}`;
    if (query) url = url + `&query=${query}`;
    if (classes.filters.class_id)
      url = url + `&class_id=${classes.filters.class_id}`;
    const value = await axios.get(url);
    dispatch(actions.updateStudents(value.data.data));
    !query && dispatch(setLoading(false));
  } catch (error) {
    dispatch(
      toggleErrorAlert(error.response?.data.errors.message || error.message)
    );
  }
};

export const updateFiles = imageList => dispatch => {
  const files = [];
  Object.values(imageList).forEach(image => files.push(image));
  dispatch(setLoading(true));
  const data = new FormData();
  for (let i = 0; i < imageList.length; i++) {
    data.append('images', imageList[i]);
  }
  return axios
    .put(`${BACKEND_API}/students/profilepictures/bulk`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      dispatch(showNotification({ message: 'Update successful' }));
      dispatch(getAllStudents());
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

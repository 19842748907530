import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footer/Footer";

import routes from "routes";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle";

import login from "assets/img/login.jpeg";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) =>
    routes
      .filter(({ layout }) => layout === "/auth")
      .map(({ collapse, views, layout, path, View }) => {
        if (collapse) return getRoutes(views);

        return <Route key={path} path={path} element={<View />} />;
      });

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + login + ")" }}
        >
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/auth/login" />} />
          </Routes>
          <Footer white />
        </div>
      </div>
    </div>
  );
}

import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

// core components
import GridItem from 'components/Grid/GridItem'
import Table from '../../../components/Table/Table'
// import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle'
import Person from 'assets/img/person.png'

import { capitalizeFirst, getFeeStatus } from '../../../helpers'
import { IMAGE_BASE } from 'consts'

const useStyles = makeStyles(styles)

function StudentsTable({
  link,
  students,
  total,
  fillButtons,
  pagination,
  handlePagination,
  hideColumns
}) {
  const classes = useStyles(),
    hideFunction = (_, i) => !hideColumns?.includes(i)

  return (
    <GridItem xs={12}>
      <Card>
        <CardBody>
          {students?.length ? (
            <Table
              page={pagination?.page}
              pages={total / pagination?.perPage}
              handlePagination={handlePagination}
              tableHead={['Student ID', 'Name', 'Class', 'Fee status'].filter(hideFunction)}
              tableData={students?.map(data => {
                const student = data.student || data
                return [
                  student?.student_id || student.id,
                  <Link href={`/admin/class/student/${student?.id}`}>
                    <img
                      src={
                        data && student && student['profilepicture']
                          ? `${IMAGE_BASE}/${student['profilepicture']}`
                          : Person
                      }
                      alt="student"
                      className={classes.profile}
                    />
                    {`${capitalizeFirst(student['firstname'])} ${
                      student['middlename']
                        ? capitalizeFirst(student['middlename'])
                        : ''
                    } ${capitalizeFirst(student['lastname'])}`}
                  </Link>,
                  capitalizeFirst(
                    student?.student_classes && student?.student_classes[0]?.session_term_class?.class?.name
                  ),
                  student?.fees
                    ? capitalizeFirst(getFeeStatus(student.fees))
                    : '',
                  // <Button color="info" size="sm">
                  //   <Link color="inherit" href={`${link}/${student['id']}`}>
                  //     Scores
                  //   </Link>
                  // </Button>,
                  // fillButtons(data),
                ].filter(hideFunction)
              })}
              customCellClasses={[classes.center, classes.left, classes.center]}
              customClassesForCells={[0, 1, 2]}
              customHeadCellClasses={[
                classes.center,
                classes.left,
                classes.center,
              ]}
              customHeadClassesForCells={[0, 1, 2]}
            />
          ) : (
            <Typography variant="body2">
              Class doesn't have any students. Click the create button to add
              students to class.
              <br />
              Contact us if you are having any difficulties.
            </Typography>
          )}
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default StudentsTable
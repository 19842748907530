import axios from 'axios'
import { actions } from './redux'
import { BACKEND_API } from 'consts'
import { checkRequiredFields, mapReponseOptions } from '../../helpers'
import {
  CSVFormatError,
  successfulCreateRequest,
  successfulUpdateRequest,
  successfulDeleteRequest,
} from '../../messages'
import { actions as commonActions } from '../../common/redux'
const {
  showNotification,
  toggleErrorAlert,
  setLoading,
  cancelConfirmation,
} = commonActions

export const getFees = () => dispatch =>
  axios
    .get(`${BACKEND_API}/fees/types`)
    .then(({ data }) => {
      dispatch(
        actions.updateField({
          key: 'fee_types',
          value: mapReponseOptions(data.data),
        })
      )
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })

export const createFee = body => dispatch => {
  dispatch(setLoading(true))
  return axios
    .post(`${BACKEND_API}/fees/types`, body)
    .then(() => {
      dispatch(getFees())
      dispatch(showNotification({ message: successfulCreateRequest('Fee') }))
      dispatch(
        actions.updateField({ key: 'open_create_type_dialog', value: false })
      )
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
}

export const editFee = ({ id, name }) => dispatch => {
  dispatch(setLoading(true))
  return axios
    .put(`${BACKEND_API}/fees/types/${id}`, { name })
    .then(() => {
      dispatch(getFees())
      dispatch(showNotification({ message: successfulUpdateRequest('Fee') }))
      dispatch(actions.updateField({ key: 'open_edit_dialog', value: false }))
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
}

export const deleteFee = id => dispatch => {
  dispatch(setLoading(true))
  return axios
    .delete(`${BACKEND_API}/fees/types/${id}`)
    .then(() => {
      dispatch(getFees())
      dispatch(showNotification({ message: successfulDeleteRequest('Fee') }))
      dispatch(cancelConfirmation())
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
}

export const createFees = fees => dispatch => {
  dispatch(setLoading(true))
  const isInvalid = fees.some(item => checkRequiredFields(item, ['name']))
  if (isInvalid) return dispatch(toggleErrorAlert(CSVFormatError()))
  return axios
    .post(`${BACKEND_API}/fees/types/bulk`, { fees })
    .then(() => {
      dispatch(getFees())
      dispatch(showNotification({ message: successfulCreateRequest('Fees') }))
      dispatch(actions.updateField({ key: 'open_csv_dialog', value: false }))
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
}

export const getClassFees = () => dispatch =>
  axios
    .get(`${BACKEND_API}/fees/class`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'class_fees', value: data.data }))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })

export const createClassFee = ({ amount, fee, ...rest }) => dispatch => {
  dispatch(setLoading(true))
  return axios
    .post(`${BACKEND_API}/fees/class`, {
      amount,
      type_id: fee,
      class_id: rest.class,
    })
    .then(() => {
      dispatch(getClassFees())
      dispatch(
        showNotification({ message: successfulCreateRequest('ClassFee') })
      )
      dispatch(actions.updateField({ key: 'open_create_dialog', value: false }))
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
}

export const editClassFee = ({ id, amount }) => dispatch => {
  dispatch(setLoading(true))
  return axios
    .put(`${BACKEND_API}/fees/class/${id}`, { amount })
    .then(() => {
      dispatch(getClassFees())
      dispatch(
        showNotification({ message: successfulUpdateRequest('ClassFee') })
      )
      dispatch(actions.updateField({ key: 'open_edit_dialog', value: false }))
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
}

export const deleteClassFee = id => dispatch => {
  dispatch(setLoading(true))
  return axios
    .delete(`${BACKEND_API}/fees/class/${id}`)
    .then(() => {
      dispatch(getClassFees())
      dispatch(
        showNotification({ message: successfulDeleteRequest('ClassFee') })
      )
      dispatch(cancelConfirmation())
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
}

export const createClassFees = fees => dispatch => {
  dispatch(setLoading(true))
  const isInvalid = fees.some(item => checkRequiredFields(item, ['name']))
  if (isInvalid) return dispatch(toggleErrorAlert(CSVFormatError()))
  return axios
    .post(`${BACKEND_API}/fees/class/bulk`, { fees })
    .then(() => {
      dispatch(getFees())
      dispatch(showNotification({ message: successfulCreateRequest('Fees') }))
      dispatch(actions.updateField({ key: 'open_csv_dialog', value: false }))
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      )
    })
}

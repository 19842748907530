import React, { createRef, useEffect } from 'react'
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import SchoolNavbar from "../components/Navbars/SchoolNavbar";
import Footer from "views/HomePage/Sections/SectionFooter";
import ErrorPage from "views/ErrorPage/ErrorPage";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle";
import { seo } from "helpers";
import { PageRoutes } from "./helper";
import { getSchool } from "views/School/actions";

const useStyles = makeStyles(styles);

function SchoolLayout({ getSchool }) {
  // ref for the wrapper div
  const wrapper = createRef();
  // styles
  const classes = useStyles();
  useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  useEffect(() => {
    seo();
    getSchool();
  }, []);
  return (
    <div>
      <SchoolNavbar />
      <div className={classes.wrapper} ref={wrapper}>
        <PageRoutes BaseComponent={ErrorPage} path="/s" />
        <Footer dark />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => state.school;

const mapDispatchToProps = (dispatch) => ({
  getSchool: () => dispatch(getSchool()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SchoolLayout)

import React from 'react'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import GridItem from 'home-components/Grid/GridItem.js'

import featuresStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js'
import { connect } from 'react-redux'
import { payment_responses } from 'consts'

const useStyles = makeStyles(featuresStyle)

function PaymentStatus({ payment }) {
  const classes = useStyles()
  return (
    <div id="about" className="cd-section">
      <div className={classNames(classes.section)}>
        <div className={classes.features}>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + ' ' + classes.mrAuto}
          >
            <h2 className={classes.title}>Status</h2>
            <h5 className={classes.description}>
              {payment_responses[payment?.status]}
            </h5>
          </GridItem>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => state.school

export default connect(mapStateToProps, null)(PaymentStatus)

/*eslint-disable*/
import React from 'react'

import Header from 'home-components/Header/Header.js'
import HeaderLinks from '../../views/HomePage/Sections/HeaderLinks.js'

export default function Components() {
  return (
    <Header
      brand="SAGE"
      links={<HeaderLinks is_home dropdownHoverColor="info" />}
      fixed
      color="transparent"
      changeColorOnScroll={{
        height: 400,
        color: 'white',
      }}
    />
  )
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  school: null,
  session_term: null,
  form: {
    subdomain: "",
    email: "",
    address: "",
    country: "",
    banner: "",
    logo: "",
    about: "",
  },
  loading: false,
};
export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
      updateField: (state, { payload }) => {
        state[payload.key] = payload.value;
      },
      updateForm: (state, action) => {
        state.form = {
          ...state.form,
          [action.field]: action.value,
        };
      },
    },
  }),
  actions = profileSlice.actions;

export default profileSlice.reducer;

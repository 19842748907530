import React from "react";
import { connect } from "react-redux";
import { WaveTopBottomLoading } from "react-loadingg";
const Container = ({ loading }) => {
  if (!loading) return null;
  return (
    <div
      style={{
        background: "rgba(0,0,0,0.2)",
        zIndex: 100000,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
      }}
    >
      <WaveTopBottomLoading />
    </div>
  );
};
const mapStateToProps = state => ({
  loading: state.common.loading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Container);

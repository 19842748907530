import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from 'components/Table/Table';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormDialog from '../../../common/components/FormDialog';
import MenuButton from '../../../common/components/MenuButton';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';

import {
  getTeachers,
  editTeacher,
  createTeacher,
  deleteTeacher,
  createTeachers,
} from '../actions';
import { actions as commonActions } from '../../../common/redux';
import { capitalizeFirst } from 'helpers';

const useStyles = makeStyles(styles);

function ManageTeachers({
  getTeachers,
  teachers,
  createTeacher,
  editTeacher,
  deleteTeacher,
  createTeachers,
}) {
  const [checked, setChecked] = React.useState([]),
    [open_create_dialog, updateCreateDialog] = React.useState(false),
    [edit_dialog, updateEditDialog] = React.useState({}),
    [open_csv_dialog, updateCSVDialog] = React.useState(false),
    handleToggle = value => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    },
    classes = useStyles(),
    fillButtons = data =>
      [
        { color: 'info', icon: Edit, func: () => updateEditDialog(data) },
        { color: 'danger', icon: Close, func: () => deleteTeacher(data?.id) },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      }),
    { id, title, ...edit_data } = edit_dialog;
  React.useEffect(() => {
    getTeachers();
  }, [getTeachers]);
  return (
    <GridContainer>
      {open_create_dialog && (
        <FormDialog
          title="Add Teacher"
          formFields={{
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
          }}
          selectFields={{
            title: ['mr', 'mrs', 'miss'].map(value => ({
              label: capitalizeFirst(value),
              value,
            })),
          }}
          handleClose={() => updateCreateDialog(false)}
          submit={body =>
            createTeacher({ ...body, reset: () => updateCreateDialog(false) })
          }
        />
      )}
      {id && (
        <FormDialog
          title="Edit teacher"
          formFields={edit_data}
          handleClose={() => updateEditDialog(false)}
          selectFields={{
            title: ['mr', 'mrs', 'miss'].map(value => ({
              label: capitalizeFirst(value),
              value,
            })),
          }}
          selectValues={{ title }}
          submit={update =>
            editTeacher({ id, ...update, reset: () => updateEditDialog(false) })
          }
        />
      )}
      {open_csv_dialog && (
        <FormDialog
          title="Create teachers from csv file"
          csvFields={['name']}
          filename="teachers"
          csvSampleDatabase={['Samuel Doe', 'Jane Sow'].map(name => ({ name }))}
          handleClose={() => updateCSVDialog(false)}
          submit={body =>
            createTeachers({ body, reset: () => updateCSVDialog(false) })
          }
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <div className={classes.cardContentRight}>
                  <MenuButton
                    title="Create"
                    class_name="floatRight"
                    buttons={[
                      {
                        name: 'Create One',
                        handler: () => updateCreateDialog(true),
                      },
                      {
                        name: 'Upload CSV',
                        handler: () => updateCSVDialog(true),
                      },
                    ]}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            {!!teachers?.length ? (
              <Table
                tableHead={['', 'Name', 'Email', 'Phone', '']}
                tableData={teachers?.map(
                  ({ id, firstname, lastname, title, email, phone }) => [
                    <Checkbox
                      key="key"
                      className={classes.positionAbsolute}
                      tabIndex={-1}
                      onClick={() => handleToggle(id)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />,
                    `${capitalizeFirst(title)} ${capitalizeFirst(
                      firstname
                    )} ${capitalizeFirst(lastname)}`,
                    email,
                    phone,
                    fillButtons({
                      id,
                      firstname,
                      lastname,
                      title,
                      email,
                      phone,
                    }),
                  ]
                )}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            ) : (
              <Typography variant="body2">
                Click on the create button to create school teachers
                <br />
                Contact us if you are having any difficulties.
              </Typography>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapDispatchToProps = dispatch => ({
  getTeachers: () => dispatch(getTeachers()),
  createTeacher: body => dispatch(createTeacher(body)),
  createTeachers: body => dispatch(createTeachers(body)),
  editTeacher: body => dispatch(editTeacher(body)),
  deleteTeacher: id =>
    dispatch(
      commonActions.showConfirmation({
        title: 'Delete teacher',
        message: 'delete teacher',
        open: true,
        handler: () => deleteTeacher(id)(dispatch),
      })
    ),
});

const mapStateToProps = state => ({
  ...state.teachers,
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageTeachers);

import axios from "axios";
import { actions } from "./redux";
import { BACKEND_API } from "consts";
import { checkRequiredFields, formatDate } from "../../helpers";
import {
  CSVFormatError,
  successfulCreateRequest,
  successfulUpdateRequest,
  successfulDeleteRequest,
} from "../../messages";
import { getSchoolProfile } from './../Profile/actions';
import { actions as commonActions } from "../../common/redux";
const {
  showNotification,
  toggleErrorAlert,
  setLoading,
  cancelConfirmation,
} = commonActions;

export const getSessions = () => dispatch =>
  axios
    .get(`${BACKEND_API}/sessions`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: "sessions", value: data.data }));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });

export const createSession = ({ start, end }) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .post(`${BACKEND_API}/sessions`, {
      start: formatDate(start),
      end: formatDate(end),
    })
    .then(() => {
      dispatch(getSessions());
      dispatch(
        showNotification({ message: successfulCreateRequest("Session") })
      );
      dispatch(
        actions.updateField({ key: "open_create_dialog", value: false })
      );
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const editSession = ({ id, ...body }) => dispatch => {
  dispatch(setLoading(true))
  return axios
    .put(`${BACKEND_API}/sessions/${id}`, body)
    .then(() => {
      dispatch(getSessions())
      dispatch(
        showNotification({ message: successfulUpdateRequest('Session') })
      )
      dispatch(actions.updateField({ key: 'open_edit_dialog', value: false }))
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })
}

export const deleteSession = id => dispatch => {
  dispatch(setLoading(true));
  return axios
    .delete(`${BACKEND_API}/sessions/${id}`)
    .then(() => {
      dispatch(getSessions());
      dispatch(
        showNotification({ message: successfulDeleteRequest("Session") })
      );
      dispatch(cancelConfirmation());
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const createSessions = sessions => dispatch => {
  dispatch(setLoading(true));
  const isInvalid = sessions.some(item => checkRequiredFields(item, ["name"]));
  if (isInvalid) return dispatch(toggleErrorAlert(CSVFormatError()));
  return axios
    .post(`${BACKEND_API}/sessions/bulk`, { sessions })
    .then(() => {
      dispatch(getSessions());
      dispatch(
        showNotification({ message: successfulCreateRequest("Sessions") })
      );
      dispatch(actions.updateField({ key: "open_csv_dialog", value: false }));
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message));
    });
};

export const updateTerm = ({ session_id, terms }) => dispatch => {
  console.log("[updateTerm]", { session_id, terms });
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/profile/term/${terms}`)
    .then(() => {
      dispatch(getSchoolProfile());
      dispatch(
        showNotification({ message: successfulUpdateRequest('Session Term') })
      )
      dispatch(actions.updateField({ key: 'open_term_dialog', value: false }))
      dispatch(setLoading(false))
    })
    .catch(error => {
      dispatch(toggleErrorAlert(error.message))
    })
};

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Code from '@material-ui/icons/Code'
import Description from '@material-ui/icons/Description'
import Dashboard from '@material-ui/icons/Dashboard'
import Storage from '@material-ui/icons/Storage'
import AccessTime from '@material-ui/icons/AccessTime'
import AttachMoney from '@material-ui/icons/AttachMoney'
import ShowChart from '@material-ui/icons/ShowChart'
import CreditCard from '@material-ui/icons/CreditCard'
import Store from '@material-ui/icons/Store'

import GridContainer from 'home-components/Grid/GridContainer.js'
import GridItem from 'home-components/Grid/GridItem.js'
import InfoArea from 'home-components/InfoArea/InfoArea.js'

import featuresStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js'

const useStyles = makeStyles(featuresStyle)

export default function Products({ ...rest }) {
  const classes = useStyles()
  return (
    <div id="products" className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.features}>
          <h2 className={classes.title}>We are offering</h2>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={ShowChart}
                title="Increased revenue"
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Description}
                title="Result checking and printing"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Dashboard}
                title="Online/Offline data management"
                iconColor="warning"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Storage}
                title="Free cloud storage"
                iconColor="primary"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={CreditCard}
                title="Fees payment"
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={AttachMoney}
                title="Revenue management"
                iconColor="rose"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Code}
                title="Free website"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Store}
                title="School store"
                iconColor="warning"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={AccessTime}
                title="Increased efficiency"
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}

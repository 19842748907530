import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from 'components/Table/Table';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormDialog from '../../../common/components/FormDialog';
import MenuButton from '../../../common/components/MenuButton';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';

import { getFees, editFee, createFee, deleteFee, createFees } from '../actions';
import { actions } from '../redux';
import { showConfirmation } from 'common/actions';
import { capitalizeFirst } from 'helpers';

const useStyles = makeStyles(styles);

function FeeTypes({
  getFees,
  fee_types,
  open_create_type_dialog,
  open_edit_type_dialog,
  open_csv_type_dialog,
  toggleDialog,
  createFee,
  editFee,
  deleteFee,
  createFees,
}) {
  const [checked, setChecked] = React.useState([]),
    handleToggle = value => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    },
    fees = useStyles(),
    [id, updateId] = React.useState(null),
    [name, updateName] = React.useState(''),
    handleEdit = (id, name) => {
      updateId(id);
      updateName(name);
      toggleDialog('open_edit_type_dialog', true);
    },
    handleDelete = id => deleteFee(id),
    fillButtons = (id, name) =>
      [
        { color: 'info', icon: Edit, func: () => handleEdit(id, name) },
        { color: 'danger', icon: Close, func: () => handleDelete(id) },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={fees.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={fees.icon} />
          </Button>
        );
      });
  React.useEffect(() => {
    getFees();
  }, [getFees]);
  return (
    <GridContainer>
      {open_create_type_dialog && (
        <FormDialog
          title="Create fee"
          formFields={{ name: '' }}
          handleClose={() => toggleDialog('open_create_type_dialog', false)}
          submit={create => createFee(create)}
        />
      )}
      {open_edit_type_dialog && (
        <FormDialog
          title="Edit fee"
          formFields={{ name }}
          handleClose={() => toggleDialog('open_edit_type_dialog', false)}
          submit={update => editFee({ id, ...update })}
        />
      )}
      {open_csv_type_dialog && (
        <FormDialog
          title="Create fees from csv file"
          csvFields={['name']}
          filename="fee-types"
          csvSampleDatabase={['school', 'uniform'].map(name => ({ name }))}
          handleClose={() => toggleDialog('open_csv_type_dialog', false)}
          submit={body => createFees(body)}
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <div className={fees.cardContentRight}>
                  <MenuButton
                    title="Create"
                    class_name="floatRight"
                    buttons={[
                      {
                        name: 'Create One',
                        handler: () =>
                          toggleDialog('open_create_type_dialog', true),
                      },
                      {
                        name: 'Upload CSV',
                        handler: () =>
                          toggleDialog('open_csv_type_dialog', true),
                      },
                    ]}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            {!!fee_types?.length ? (
              <Table
                tableHead={['', 'Name', '']}
                tableData={fee_types?.map(({ name, id }) => [
                  <Checkbox
                    key="key"
                    className={fees.positionAbsolute}
                    tabIndex={-1}
                    onClick={() => handleToggle(id)}
                    checkedIcon={<Check className={fees.checkedIcon} />}
                    icon={<Check className={fees.uncheckedIcon} />}
                    classes={{
                      checked: fees.checked,
                      root: fees.checkRoot,
                    }}
                  />,
                  capitalizeFirst(name),
                  fillButtons(id, name),
                ])}
                customCellClasses={[fees.center, fees.right, fees.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[fees.center, fees.right, fees.right]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            ) : (
              <Typography variant="body2">
                Click on the create button to create school fees
                <br />
                Contact us if you are having any difficulties.
              </Typography>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapDispatchToProps = dispatch => ({
  getFees: () => dispatch(getFees()),
  createFee: body => dispatch(createFee(body)),
  createFees: body => dispatch(createFees(body)),
  editFee: body => dispatch(editFee(body)),
  deleteFee: id =>
    dispatch(
      showConfirmation('Delete fee', 'delete fee', () =>
        deleteFee(id)(dispatch)
      )
    ),
  toggleDialog: (key, value) => dispatch(actions.updateField({ key, value })),
});

const mapStateToProps = state => ({
  ...state.fees,
});
export default connect(mapStateToProps, mapDispatchToProps)(FeeTypes);

import React, { useState } from 'react';
import { useCSVReader } from "react-papaparse";
import ImageUploader from "react-images-upload";
import DateFnsUtils from "@date-io/date-fns";
import { CSVLink } from "react-csv";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import {
  capitalizeFirst,
  checkInvalid,
  sumValuesInArray,
  concatGradeScore,
} from "helpers";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

const FormDialog = ({
  size,
  title,
  submit,
  filename,
  csvFields,
  formFields,
  fileFields,
  dateFields,
  csvDatabase,
  handleClose,
  description,
  selectFields,
  numberFields,
  selectValues,
  fullWidthDate,
  csvSampleLink,
  skipValidation,
  disabledFields,
  csvSampleDatabase,
  dialogSize = "sm",
}) => {
  const { CSVReader } = useCSVReader();
  let selectObj;
  if (selectFields) {
    selectObj = {};
    for (let field in selectFields) {
      selectObj[field] = (selectValues && selectValues[field]) || "";
    }
  }
  const [form, setForm] = useState(formFields),
    [numbers, setNumbers] = useState(numberFields),
    [select, setSelect] = useState(selectObj),
    [files, setfile] = useState(fileFields),
    [dates, setdate] = useState(dateFields),
    [csv, setCSV] = useState(null),
    classes = useStyles(),
    handleChange = ({ target }) =>
      setForm({ ...form, [target.name]: target.value }),
    handleNumberChange = ({ target }) =>
      setNumbers({ ...numbers, [target.name]: parseInt(target.value) }),
    handleSelectChange = ({ target }) =>
      setSelect({ ...select, [target.name]: target.value }),
    handleDateChange = (name, value) => setdate({ ...dates, [name]: value }),
    handleFileChange = (value, field) =>
      setfile({ ...files, [field]: value[0] }),
    isFormInvalid = skipValidation
      ? false
      : Object.keys(csv ? csv : { ...form, ...files, ...dates, ...select })
            .length
        ? checkInvalid({ ...form, ...files, ...select })
        : true,
    csvHelperText = (
      <>
        Upload your csv file.{" "}
        {csvSampleLink && (
          <Link href={csvSampleLink} target="_blank">
            <u>
              <i>Download sample</i>
            </u>
          </Link>
        )}{" "}
        {csvSampleDatabase && (
          <CSVLink
            target="_blank"
            data={csvSampleDatabase}
            filename={`${filename ? filename + "-" : ""}sample.csv`}
          >
            <u>
              <i>Download sample</i>
            </u>
          </CSVLink>
        )}
        .{" "}
        {csvDatabase && (
          <CSVLink
            target="_blank"
            data={csvDatabase}
            filename={`${filename ? filename + "-" : ""}database.csv`}
          >
            <u>
              <i>Download current database</i>
            </u>
          </CSVLink>
        )}{" "}
      </>
    ),
    onDrop = (data) => {
      const parsedData = data.slice(1).map(({ data }) => {
        const item = {};
        csvFields.forEach((key, i) => {
          item[key] = data[i];
        });
        return item;
      });
      setCSV(parsedData);
    },
    reset = () => {
      setForm(formFields);
      setfile(fileFields);
      if (selectObj) setSelect(selectObj);
      setdate(dateFields);
      handleClose();
    };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(
      csv
        ? csv
        : {
            ...form,
            ...numbers,
            ...files,
            ...dates,
            ...select,
          },
      reset,
    );
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={dialogSize}
    >
      <DialogTitle id="form-dialog-title" classes={{ root: classes.text }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            {description ||
              (csvFields && (
                <DialogContentText>
                  {csvFields ? csvHelperText : description}
                </DialogContentText>
              ))}
            {form &&
              Object.keys(form).map((item, i) => (
                <Grid item xs={12} md={size} key={i}>
                  <TextField
                    autoFocus={i === 0 ? true : false}
                    margin="dense"
                    id={item}
                    name={item}
                    label={capitalizeFirst(item.replace("_", " "))}
                    type="text"
                    value={form[item] || ""}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      className: classes.text,
                    }}
                  />
                </Grid>
              ))}
            {numbers &&
              Object.keys(numbers).map((item, i) => (
                <Grid item xs={12} md={size} key={i}>
                  <TextField
                    margin="dense"
                    id={item}
                    name={item}
                    autoFocus={!form && i === 0 ? true : false}
                    label={capitalizeFirst(item.replace("_", " "))}
                    type="number"
                    value={numbers[item] || ""}
                    onChange={handleNumberChange}
                    fullWidth
                    InputProps={{
                      className: classes.text,
                    }}
                  />
                </Grid>
              ))}
            {selectFields &&
              Object.keys(selectFields)?.map((item, i) => (
                <Grid item xs={12} md={size} key={i}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id={`${item}-select-label`}>
                      {capitalizeFirst(item.replace("_", " "))}
                    </InputLabel>
                    <Select
                      labelId={`${item}-select-label`}
                      id={`${item}-select`}
                      value={
                        select[item] ||
                        (selectValues && selectValues[item]) ||
                        ""
                      }
                      onChange={handleSelectChange}
                      name={item}
                    >
                      {selectFields[item]?.map(({ value, label }) => (
                        <MenuItem value={value} key={value}>
                          {capitalizeFirst(label.replace("_", " "))}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ))}
            {dates && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  {Object.keys(dates).map((item, i) => (
                    <KeyboardDatePicker
                      fullWidth={!!fullWidthDate}
                      key={i}
                      margin="normal"
                      id={`${item}-date-picker-dialog`}
                      label={capitalizeFirst(item.replace("_", " "))}
                      format="yyyy/MM/dd"
                      value={dates[item]}
                      onChange={(event) => handleDateChange(item, event)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  ))}
                </Grid>
              </MuiPickersUtilsProvider>
            )}
            {files &&
              Object.keys(files).map((field, i) => (
                <Grid item xs={12} md={size} key={i}>
                  <ImageUploader
                    buttonText={`Select ${field}`}
                    label={"Please select an appropriate image"}
                    onChange={(file) => handleFileChange(file, field)}
                    imgExtension={[".jpg", ".png", ".jpeg"]}
                    maxFileSize={15728640}
                    singleImage
                    withPreview
                    withIcon={false}
                    fileContainerStyle={{
                      border: "1px solid #eaeaea",
                      boxShadow: "none",
                      color: "#2F313F",
                    }}
                  />
                </Grid>
              ))}
            {csvFields && (
              <GridContainer>
                <GridItem xs={12}>
                  <CSVReader
                    onDrop={onDrop}
                    onError={console.error}
                    onRemoveFile={console.log}
                  >
                    <span style={{ color: "#2F313F" }}>
                      Drop CSV file here or click to upload.
                    </span>
                  </CSVReader>
                </GridItem>
              </GridContainer>
            )}
            {disabledFields &&
              Object.keys(disabledFields).map((item, i) => {
                let value = sumValuesInArray(numbers, disabledFields[item]);
                if (item === "total") value = concatGradeScore(value);
                return (
                  <Grid item xs={12} md={size} key={i}>
                    <TextField
                      disabled
                      id={item}
                      fullWidth
                      name={item}
                      type="text"
                      margin="dense"
                      autoFocus={i === 0 ? true : false}
                      value={value || 0}
                      label={capitalizeFirst(item.replace("_", " "))}
                      InputProps={{
                        className: classes.text,
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disableElevation
          disabled={isFormInvalid}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;

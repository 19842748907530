import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { actions } from "common/redux";

const useStyles = makeStyles(styles);

const ErrorDialog = ({ message, close }) => {
  const classes = useStyles();
  if (!message) return null;
  return (
    <SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      title="Error"
      onConfirm={close}
      confirmBtnCssClass={classes.button + " " + classes.info}
    >
      {message}
    </SweetAlert>
  );
};
const mapStateToProps = state => ({
  message: state.common.error,
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(actions.toggleErrorAlert("")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);

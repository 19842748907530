import React from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormDialog from "../../../common/components/FormDialog";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

import {
  getSessions,
  editSession,
  createSession,
  deleteSession,
  updateTerm,
} from "../actions";
import { getSchoolProfile } from '../../Profile/actions';
import { getTerm, getTerms } from "../../Terms/actions";

import { actions } from "../redux";
import { actions as commonActions } from "../../../common/redux";
import { formatDate, formatDateFriendly } from "../../../helpers";

const useStyles = makeStyles(styles);

function ManageSessions({
  getSessions,
  sessions,
  open_create_dialog,
  open_edit_dialog,
  open_term_dialog,
  toggleDialog,
  createSession,
  editSession,
  deleteSession,
  getSchoolProfile,
  getTerms,
  getTerm,
  term_id,
  current,
  terms,
  updateTerm,
}) {
  const classes = useStyles(),
    [id, updateId] = React.useState(null),
    [start, updateStart] = React.useState(''),
    [end, updateEnd] = React.useState(''),
    handleEdit = (id, start, end) => {
      updateId(id);
      if (start) updateStart(start);
      if (end) updateEnd(end);
      toggleDialog('open_edit_dialog', true);
    },
    handleDelete = id => deleteSession(id),
    fillButtons = (id, start, end) =>
      [
        { color: 'info', icon: Edit, func: () => handleEdit(id, start, end) },
        { color: 'danger', icon: Close, func: () => handleDelete(id) },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
  terms = terms?.filter(({ value }) => value !== current?.term?.id);

  React.useEffect(() => {
    getSessions();
    getSchoolProfile();
    getTerms();
  }, [getSessions, getSchoolProfile, getTerms, open_term_dialog]);
  React.useEffect(() => {
    if (term_id) getTerm(term_id);
  }, [getTerm, term_id]);
  return (
    <GridContainer>
      {open_create_dialog && (
        <FormDialog
          title="Create session"
          dateFields={{ start: new Date(), end: new Date() }}
          handleClose={() => toggleDialog('open_create_dialog', false)}
          submit={create => createSession(create)}
        />
      )}

      {open_edit_dialog && (
        <FormDialog
          title="Edit session"
          dateFields={{ start: new Date(start), end: new Date(end) }}
          handleClose={() => toggleDialog('open_edit_dialog', false)}
          submit={update => editSession({ id, ...update })}
        />
      )}
      {open_term_dialog && (
        <FormDialog
          title="Select term"
          dialogSize="xs"
          selectFields={{
            terms,
          }}
          currentSelectValue={term_id}
          handleClose={() => toggleDialog('open_term_dialog', false)}
          submit={update =>
            updateTerm({ session_id: sessions && sessions[0].id, ...update })
          }
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={9}>
                <Typography variant="subtitle2">
                  Current Session:{' '}
                  <u>
                    {current?.session
                      ? `${formatDate(current?.session.start)?.split('/')[0]}/${
                          formatDate(current?.session.end).split('/')[0]
                        }`
                      : 'No session created'}
                  </u>
                </Typography>
                <Typography variant="subtitle2">
                  Current Term:{' '}
                  <u>
                    {current?.term?.name
                      ? current?.term?.name
                      : 'No term selected'}
                  </u>{' '}
                  {!!terms?.length && (
                    <Button
                      disabled={!sessions?.length || !terms?.length}
                      onClick={() => toggleDialog('open_term_dialog', true)}
                      color="primary"
                      size="sm"
                      className={classes.marginRight}
                    >
                      Update term
                    </Button>
                  )}
                </Typography>
              </GridItem>
              <GridItem xs={3}>
                <div className={classes.floatRight}>
                  <Button
                    onClick={() => toggleDialog('open_create_dialog', true)}
                    className={classes.right}
                    color="success"
                    aria-controls="fade-menu"
                  >
                    New session
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            {sessions?.length ? (
              <Table
                tableHead={['Name', 'Start', 'End', '']}
                tableData={sessions?.map(({ start, end, id }) => [
                  `${formatDate(start)?.split('/')[0]}/${
                    formatDate(end).split('/')[0]
                  } Session`,
                  formatDateFriendly(start),
                  formatDateFriendly(end),
                  fillButtons(id, start, end),
                ])}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            ) : (
              <Typography variant="body2">
                Click on the create button to create school subjects
                <br />
                Contact us if you are having any difficulties.
              </Typography>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapDispatchToProps = dispatch => ({
  getSessions: () => dispatch(getSessions()),
  createSession: body => dispatch(createSession(body)),
  editSession: body => dispatch(editSession(body)),
  deleteSession: id =>
    dispatch(
      commonActions.showConfirmation({
        title: 'Delete session',
        message: 'delete session',
        open: true,
        handler: () => deleteSession(id)(dispatch),
      })
    ),
  toggleDialog: (key, open) =>
    dispatch(actions.updateField({ key, value: open })),
  getSchoolProfile: () => dispatch(getSchoolProfile(true)),
  getTerms: () => dispatch(getTerms()),
  getTerm: id => dispatch(getTerm(id)),
  updateTerm: body => dispatch(updateTerm(body)),
});

const mapStateToProps = state => ({
  ...state.sessions,
  term_id: state.profile?.school?.current_term,
  current: state.terms.term,
  terms: state.terms.terms?.map(({ id, name }) => ({ value: id, label: name })),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageSessions);

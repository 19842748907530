import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

// material-ui icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormDialog from "../../../common/components/FormDialog";
import MenuButton from "../../../common/components/MenuButton";
import CustomDialog from "../../../common/components/CustomDialog";
import StudentsTable from "../components/StudentsTable";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

import {
  getTermClassStudents,
  deleteTermClassStudent,
  createTermClassStudent,
  createTermClassStudents,
  getTermClass,
  updateTermClassTeacher,
} from "../actions";

import { getCurrentSessionTerm } from "../../Profile/actions";
import { getTeachers } from "../../Teachers/actions";
import { actions } from "../redux";
import { actions as commonActions } from "../../../common/redux";
import { capitalizeFirst, formatDate } from "../../../helpers";

const useStyles = makeStyles(styles);

function TermClasses({
  getStudents,
  term_class_students,
  deleteStudent,
  toggleDialog,
  open_create_dialog,
  open_csv_dialog,
  getCurrentSessionTerm,
  session_term,
  getTermClass,
  term_class,
  addStudent,
  addStudents,
  teachers,
  updateTeacher,
  getTeachers,
}) {
  const { id: class_id } = useParams(),
    [class_teacher_dialog, updateTeacherDialog] = React.useState(),
    classes = useStyles(),
    handleDelete = (class_id, id) => deleteStudent(class_id, id),
    fillButtons = (id) =>
      [
        {
          color: "danger",
          icon: Close,
          func: () => handleDelete(class_id, id),
        },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
  useEffect(() => {
    getCurrentSessionTerm();
    getStudents(class_id);
    getTermClass(class_id);
  }, [getCurrentSessionTerm, getStudents, class_id, getTermClass]);
  return (
    <GridContainer>
      {class_teacher_dialog && teachers?.length && (
        <FormDialog
          fullWidthDate
          title="Select teacher"
          selectFields={{
            teacher: teachers.map((t) => ({
              value: t.id,
              label: `${capitalizeFirst(t.title)} ${capitalizeFirst(
                t.firstname,
              )} ${capitalizeFirst(t.lastname)}`,
            })),
          }}
          selectValues={{ teacher: term_class["account.id"] }}
          handleClose={() => updateTeacherDialog(false)}
          submit={({ teacher }) =>
            updateTeacher({
              id: class_id,
              teacher_id: teacher,
              reset: () => updateTeacherDialog(false),
            })
          }
        />
      )}
      {class_teacher_dialog && teachers?.length === 0 && (
        <CustomDialog size="xs" handleClose={() => updateTeacherDialog(false)}>
          <Typography variant="body1">
            No teacher created. Click <Link href={`/admin/teachers`}>here</Link>{" "}
            to create teachers.
          </Typography>
        </CustomDialog>
      )}
      {open_create_dialog && (
        <FormDialog
          fullWidthDate
          title="Add student"
          formFields={{
            firstname: "",
            middlename: "",
            lastname: "",
          }}
          dateFields={{ dob: new Date() }}
          selectFields={{
            gender: [
              { label: "Male", value: "male" },
              { label: "Female", value: "female" },
            ],
          }}
          handleClose={() => toggleDialog("open_create_dialog", false)}
          submit={(body) => addStudent(body)}
        />
      )}
      {open_csv_dialog && (
        <FormDialog
          title="Create classes from csv file"
          csvFields={["firstname", "lastname", "middlename", "gender", "dob"]}
          filename="class-students"
          csvSampleDatabase={[
            {
              firstname: "ciroma",
              middlename: "kunle",
              lastname: "ebuka",
              gender: "male",
              dob: "2002-11-15",
            },
            {
              firstname: "chioma",
              middlename: "aisha",
              lastname: "tunde",
              gender: "female",
              dob: "2003-01-03",
            },
          ]}
          handleClose={() => toggleDialog("open_csv_dialog", false)}
          submit={(body) => addStudents(body)}
        />
      )}
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={9}>
                <Typography variant="subtitle2">
                  Session:{" "}
                  <u>
                    {session_term
                      ? `${formatDate(session_term?.start)?.split("/")[0]}/${
                          formatDate(session_term?.end).split("/")[0]
                        }`
                      : "No session created"}
                  </u>
                </Typography>
                <Typography variant="subtitle2">
                  Term:{" "}
                  <u>
                    {session_term?.current_term
                      ? session_term?.current_term
                      : "No term created yet"}
                  </u>{" "}
                </Typography>
                <Typography variant="subtitle2">
                  Class: <u>{term_class && term_class["class.name"]}</u>{" "}
                </Typography>
                <Typography variant="subtitle2">
                  Students: <u>{term_class_students?.length}</u>{" "}
                </Typography>
                <Typography variant="subtitle2">
                  Class teacher:{" "}
                  {term_class && (
                    <u>{`${capitalizeFirst(
                      term_class["account.title"],
                    )} ${capitalizeFirst(
                      term_class["account.firstname"],
                    )} ${capitalizeFirst(term_class["account.lastname"])}`}</u>
                  )}{" "}
                  <Button
                    onClick={() => {
                      getTeachers();
                      updateTeacherDialog(true);
                    }}
                    color="primary"
                    size="sm"
                    className={classes.marginRight}
                  >
                    Update
                  </Button>
                </Typography>
              </GridItem>
              <GridItem xs={3}>
                <div className={classes.cardContentRight}>
                  <div className={classes.cardContentRight}>
                    <MenuButton
                      title="Add student"
                      class_name="floatRight"
                      buttons={[
                        {
                          name: "Add One",
                          handler: () =>
                            toggleDialog("open_create_dialog", true),
                        },
                        {
                          name: "Upload CSV",
                          handler: () => toggleDialog("open_csv_dialog", true),
                        },
                      ]}
                    />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <StudentsTable
        hideColumns={[2]}
        students={term_class_students}
        fillButtons={fillButtons}
        link={`/admin/students/scores/${class_id}`}
      />
    </GridContainer>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentSessionTerm: () => dispatch(getCurrentSessionTerm()),
  getTermClass: (id) => dispatch(getTermClass(id)),
  getStudents: (id) => dispatch(getTermClassStudents(id)),
  addStudent: (body) => dispatch(createTermClassStudent(body)),
  addStudents: (body) => dispatch(createTermClassStudents({ body })),
  getTeachers: () => dispatch(getTeachers()),
  updateTeacher: (body) => dispatch(updateTermClassTeacher(body)),
  deleteStudent: (class_id, id) =>
    dispatch(
      commonActions.showConfirmation({
        title: "Delete student",
        message: "delete student from class",
        open: true,
        handler: () => deleteTermClassStudent(class_id, id)(dispatch),
      }),
    ),
  toggleDialog: (key, open) =>
    dispatch(actions.updateField({ key, value: open })),
});

const mapStateToProps = (state) => ({
  ...state.classes,
  session_term: state.profile.session_term,
  teachers: state.teachers.teachers,
});
export default connect(mapStateToProps, mapDispatchToProps)(TermClasses);

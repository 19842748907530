import axios from 'axios'
import { actions } from './redux'

const { toggleErrorAlert } = actions

export const showConfirmation = (title, message, handler) => (
  dispatch,
  getState
) =>
  dispatch(
    actions.showConfirmation({
      title,
      message,
      handler: d => handler(d)(dispatch, getState),
    })
  )

export const handleError = error => (dispatch, getState) => {
  const message = error.response?.data.errors.message || error.message
  if (error.response?.status === 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    axios.defaults.headers.common['Authorization'] = ``
    const current_path = getState().router.location.pathname
    return (window.location = `/auth/login?path=${current_path}`)
  }
  return dispatch(toggleErrorAlert(message))
}

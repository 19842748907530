import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { actions } from "common/redux";

const useStyles = makeStyles(styles);

const ConfirmationDialog = ({
  title,
  message,
  open,
  dispatchHandler,
  handler,
  cancel,
}) => {
  const classes = useStyles()
  if (!open || !handler) return null
  return (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-100px' }}
      title={`Confirm ${title}`}
      onCancel={cancel}
      onConfirm={handler}
      cancelBtnText="Cancel"
      cancelBtnCssClass={classes.button + ' ' + classes.danger}
      confirmBtnCssClass={classes.button + ' ' + classes.info}
      showCancel
    >
      {`Are you sure you want to ${message}?`}
    </SweetAlert>
  )
}
const mapStateToProps = state => ({
  ...state.common.confirm_dialog,
})

const mapDispatchToProps = dispatch => ({
  cancel: () => dispatch(actions.cancelConfirmation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog);

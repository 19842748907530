import axios from 'axios';
import { actions } from './redux';
import { BACKEND_API } from 'consts';
import { actions as commonActions } from 'common/redux';
import { handleError } from 'common/actions';
const { showNotification, setLoading, toggleErrorAlert } = commonActions;

export const getSchoolProfile = fromCache => async dispatch => {
  try {
    let value;
    if (fromCache) {
      const cachedInfo = localStorage.getItem('school');
      if (cachedInfo) value = JSON.parse(cachedInfo);
      // if (value?.current_term) delete value.current_term;
    }
    if (!value) {
      value = (await axios.get(`${BACKEND_API}/profile`)).data?.data;
      value && localStorage.setItem('school', JSON.stringify(value));
    }
    dispatch(actions.updateField({ key: 'school', value }));
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateProfile = ({ body, reset }) => dispatch => {
  dispatch(setLoading(true));
  return axios
    .put(`${BACKEND_API}/profile`, body)
    .then(() => {
      dispatch(showNotification({ message: 'Update successful' }));
      dispatch(getSchoolProfile());
      reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(error.response?.data.errors.message || error.message)
      );
    });
};

export const getCurrentSessionTerm = () => dispatch => {
  return axios
    .get(`${BACKEND_API}/profile/session-term`)
    .then(({ data }) => {
      dispatch(actions.updateField({ key: 'session_term', value: data.data }));
    })
    .catch(error => {
      let errors = ``;
      for (const e in error.response?.data.errors) {
        errors += `<p>${e}</p>`;
      }
      dispatch(toggleErrorAlert(errors || error.message));
    });
};

export const updateFile = ({ body, reset }) => dispatch => {
  dispatch(setLoading(true));
  const field = Object.keys(body)[0],
    data = new FormData();
  data.append(field, body[field]);
  return axios
    .put(`${BACKEND_API}/profile/${field}`, data)
    .then(() => {
      dispatch(showNotification({ message: 'Update successful' }));
      dispatch(getSchoolProfile());
      reset();
      dispatch(setLoading(false));
    })
    .catch(error => {
      dispatch(
        toggleErrorAlert(
          error.response?.data?.errors?.message || error?.message
        )
      );
    });
};
